<!--
 * @Author: 肖槿
 * @Date: 2020-06-16 09:48:39
 * @Description: 小店新增/修改
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-06-18 11:09:00
 * @FilePath: \s2b-admin\src\components\admin\shop\shop-detail.vue
-->
<template>
  <div>
    <a-form-model
      ref="shopForm"
      :model="shopData"
      :rules="rules"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 15 }"
      :form="form"
      @submit="handleFormSubmit"
    >
      <a-form-model-item label="小店名称：" prop="name">
        <a-input v-model.trim="shopData.name" allowClear placeholder="请输入小店名称" />
      </a-form-model-item>
      <a-form-model-item label="联系电话：" prop="contact_mobile">
        <a-input
          v-model="shopData.contact_mobile"
          @keyup="onInputMobile"
          @input="onInputMobile"
          allowClear
          placeholder="请输入联系电话"
        />
      </a-form-model-item>
      <a-form-model-item label="小店类型：" prop="shop_type">
        <a-select v-model="shopData.shop_type" placeholder="全部" allowClear style="width:190px;">
          <a-select-option v-for="(item, k) in Brand" :key="k" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="经营范围：" prop="first_cat_ids">
        <a-checkbox-group
          v-model="shopData.first_cat_ids"
          name="checkboxgroup"
          :options="category"
          @change="catChange"
        >
          <span slot="value" slot-scope="{ value }">{{ value }}</span>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="App key：" prop="app_key">
        <a-input v-model="shopData.app_key" allowClear placeholder="请输入Key" />
      </a-form-model-item>
      <a-form-model-item label="授权码：" prop="app_secret">
        <a-input v-model="shopData.app_secret" allowClear placeholder="请输入授权码" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" html-type="submit" :loading="loading" style="margin-right:20px;"
          >确定</a-button
        >
        <a-button :loading="loading" @click="formReset">取消</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Brand } from '@/const/shop';
import { deepClone } from '@/utils';
import { CreateShop } from '@/service/shop2';

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      Brand,
      shopData: {
        name: '',
        contact_mobile: '',
        shop_type: '',
        app_key: '',
        app_secret: '',
        first_cat_ids: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: '请填写小店名称',
            trigger: 'blur',
          },
          {
            max: 50,
            message: '小店名称最长为50个字符',
            trigger: 'blur',
          },
        ],
        contact_mobile: [
          {
            required: true,
            message: '请填写联系电话',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (/^\d{11}$/.test(value)) {
                callback();
              }

              callback(new Error('请填写正确的联系电话'));
            },
            trigger: 'blur',
          },
        ],
        shop_type: [
          {
            required: true,
            message: '请选择小店类型',
            trigger: 'blur',
          },
        ],
        first_cat_ids: [
          {
            required: true,
            message: '请选择经营范围',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('请选择经营范围'));
              }

              callback();
            },
            trigger: 'blur',
          },
        ],
        app_key: [
          {
            required: true,
            message: '请填写 App key',
            trigger: 'blur',
          },
        ],
        app_secret: [
          {
            required: true,
            message: '请填写授权码',
            trigger: 'blur',
          },
        ],
      },
      plainOptions: [
        {
          label: '一级类目A',
          value: 1,
        },
        {
          label: '一级类目B',
          value: 2,
        },
        {
          label: '一级类目C',
          value: 3,
        },
        {
          label: '一级类目D',
          value: 4,
        },
        {
          label: '一级类目E',
          value: 5,
        },
        {
          label: '一级类目F',
          value: 6,
        },
        {
          label: '一级类目G',
          value: 7,
        },
      ],
    };
  },
  props: {
    category: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    /**
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 14:51:26
     */
    handleFormSubmit(event) {
      event.preventDefault();
      this.$refs.shopForm.validate(valid => {
        if (valid) {
          // do nth
          this.createShop();
        } else {
          // do nth
        }
      });
    },
    /**
     * 保存小店
     * @author Jerry <superzcj_001@163.com>
     * @since  2020-06-28 15:44:04
     */
    async createShop() {
      const { first_cat_ids } = this.shopData;
      const payload = {
        ...this.shopData,
        first_cat_ids: first_cat_ids.join(','),
      };

      const { res } = await CreateShop(payload);
      if (res !== null) {
        if (res.error_code === 0) {
          this.$message.success('新增小店成功');
          this.$emit('success');
        } else {
          this.$message.error('新增小店失败');
        }
      } else {
        this.$message.error('新增小店失败');
      }
    },
    onInputMobile(event) {
      const value = event.target.value;

      const val = value.replace(/\D+/g, '');

      const result = /\d{0,11}/g.exec(val);

      if (result === null) {
        this.shopData.contact_mobile = '';
        event.target.value = '';
      } else {
        this.shopData.contact_mobile = result[0];
        event.target.value = result[0];
      }
    },
    show(v) {
      this.shopData = deepClone(v);
    },
    formReset() {
      this.$refs.shopForm.resetFields();
      this.$emit('close');
    },
    catChange() {
    },
  },
  mounted() {
    this.$refs.shopForm.resetFields();
  },
};
</script>

<style></style>
