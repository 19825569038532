<!--
 * @Author: 肖槿
 * @Date: 2020-06-15 15:07:55
 * @Description: 账户管理搜索组件
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-14 10:23:14
 * @FilePath: \s2b-admin\src\components\admin\shop\account-management-search.vue
-->
<template>
  <div>
    <a-form layout="inline" :form="form" @submit="handleFormSubmit">
      <div class="filter-condition-form">
        <a-form-item label="商家名称：">
          <a-input v-model.trim="queryParams.merchant_name" allowClear placeholder="请输入商家名称" style="width:190px;" />
        </a-form-item>
        <a-form-item label="是否缴纳保证金：">
          <a-select v-model="queryParams.pay_deposit_status" style="width: 190px">
            <a-select-option value="0">全部</a-select-option>
            <a-select-option value="2">是</a-select-option>
            <a-select-option value="1">否</a-select-option>
          </a-select>
        </a-form-item>

        <!--<a-form-item label="未处理提现申请：">-->
        <!--<a-select v-model="queryParams.type" placeholder="全部" allowClear style="width:190px;">-->
        <!--<a-select-option v-for="(item, k) in Withdraw" :key="k" :value="item.value">{{item.label}}</a-select-option>-->
        <!--</a-select>-->
        <!--</a-form-item>-->

        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="loading" style="margin-right:20px;">查询</a-button>
          <a-button :loading="loading" @click="formReset">重置</a-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import { Withdraw } from "@/const/shop";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      Withdraw,
      queryParams: {
        pay_deposit_status: "0",
      },
    };
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.$emit("success", this.queryParams);
    },
    formReset() {
      this.form.resetFields();
      this.queryParams = this.form.getFieldsValue();
      this.$emit("success", this.queryParams);
    },
  },
};
</script>

<style lang="less" scoped>
.filter-condition-form {
  margin-bottom: 20px;
}
</style>
