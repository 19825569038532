/*
 * @Author: 肖槿
 * @Date: 2020-06-12 16:47:31
 * @Description: 小店管理中需要用到的常量
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-13 15:06:09
 * @FilePath: \s2b-admin\src\const\shop.js
 */

// 认证状态
export const Certification = [
  {
    label: '已认证',
    value: 3
  },
  {
    label: '未认证',
    value: 1
  },
  {
    label: '认证中',
    value: 2
  },
  {
    label: '认证失败',
    value: 4
  }
]

// 是否缴纳保证金
export const Margin = [
  {
    label: '已缴纳',
    value: 2
  },
  {
    label: '未缴纳',
    value: 1
  }
]

// 有无提现申请
export const Withdraw = [
  {
    label: '有',
    value: 1
  },
  {
    label: '无',
    value: 0
  }
]

// 证件名称
export const LicenseType = [
  {
    label: '头像面',
    value: 1
  },
  {
    label: '国徽面',
    value: 2
  },
  {
    label: '营业执照',
    value: 3
  },
  {
    label: '组织机构代码证',
    value: 4
  },
  {
    label: '税务登记证',
    value: 5
  },
  {
    label: '开户许可证',
    value: 6
  },
  {
    label: '商标注册证',
    value: 7
  },
  {
    label: '注册申请受理证书',
    value: 8
  },
  {
    label: '授权文件',
    value: 9
  },
  {
    label: '其他证书',
    value: 10
  },
]

// 启用禁用
export const Enabled = [
  {
    label: '启用',
    value: 1
  },
  {
    label: '禁用',
    value: 0
  }
]

// 品牌类型
export const Brand = [
  {
    label: '自有品牌',
    value: 1
  },
  {
    label: '代理品牌',
    value: 2
  }
]

// 订单状态
export const OrderStatus = [
  {
    label: '待支付',
    value: 3
  },
  {
    label: '备货中',
    value: 4
  },
  {
    label: '已发货',
    value: 5
  },
  {
    label: '已完成',
    value: 1
  },
  {
    label: '已关闭',
    value: 6
  },
  {
    label: '已退款',
    value: 7
  },
  {
    label: '退货中',
    value: 8
  }
]

// 价格/库存审核状态
export const AuditType = [
  {
    label: '通过',
    value: 1
  },
  {
    label: '拒绝',
    value: 2
  },
  {
    label: '待审核',
    value: 0
  }
]

// 审核结果
export const AuditResult = [
  {
    label: '已通过',
    value: 3
  },
  {
    label: '未通过',
    value: 4
  }
]


// 商家列表表格数据
export const ShopTableList = [
  {
    title: '注册手机号',
    dataIndex: 'user_mobile'
  },
/*  {
    title: '商家认证',
    scopedSlots: { customRender: "auth" }
  },*/
  // {
  //   title: '保证金',
  //   scopedSlots: { customRender: "money" }
  // },
  {
    title: '商家名称',
    scopedSlots: { customRender: "name" }
  },
/*  {
    title: '品牌类型',
    scopedSlots: { customRender: "type" }
  },*/
  {
    title: '对接人手机号',
    scopedSlots: { customRender: "contact_tel" }
  },
  {
    title: '对接招商',
    scopedSlots: { customRender: "contact__username" }
  },
  {
    title: '注册时间',
    scopedSlots: { customRender: "user_create_time" }
  },
  {
    title: '最后访问',
    scopedSlots: { customRender: "last_login_time" }
  },
 /* {
    title: '操作',
    scopedSlots: { customRender: "action" }
  }*/
]

// 商家认证申请
export const ShopCertification = [
  {
    title: '公司名称',
    ellipsis: true,
    dataIndex: 'merchant_name'
  },
  {
    title: '店铺名称',
    ellipsis: true,
    dataIndex: 'shop_name'
    // scopedSlots: { customRender: "type" }
  },
  {
    title: '商家类型',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: "brand_type" }
  },
  {
    title: '注册手机号',
    dataIndex: 'user_mobile'
  },
  {
    title: '申请时间',
    scopedSlots: { customRender: "apply_time" }
  },
  {
    title: '操作',
    scopedSlots: { customRender: "audit" }
  }
]

// 商家审核记录
export const ShopAudit = [
  {
    title: '公司名称',
    ellipsis: true,
    dataIndex: 'merchant_name'
  },
  {
    title: '店铺名称',
    ellipsis: true,
    dataIndex: 'shop_name'
    // scopedSlots: { customRender: "type" }
  },
  {
    title: '商家类型',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: "brand_type" }
  },
  {
    title: '注册手机号',
    dataIndex: 'user_mobile'
  },
  {
    title: '审核结果',
    scopedSlots: { customRender: "result" }
  },
  {
    title: '审核人',
    dataIndex: 'audit_user_name'
  },
  {
    title: '审核时间',
    scopedSlots: { customRender: "audit_time" }
  },
  {
    title: '申请时间',
    scopedSlots: { customRender: "apply_time" }
  },
  {
    title: '操作',
    scopedSlots: { customRender: "action" }
  }
]

// 商品状态
export const GoodsType = [
  {
    label: '待审核',
    value: 1
  },
  {
    label: '待上架',
    value: 3
  },
  {
    label: '小店审核中',
    value: 4
  },
  {
    label: '已上架',
    value: 7
  },
  {
    label: '已下架',
    value: 8
  },
  {
    label: '初审不通过',
    value: 2
  },
  {
    label: '小店审核不通过',
    value: 6
  },
  {
    label: '小店审核不通过',
    value: 5
  },
  {
    label: '价格变更审核',
    value: 111
  },
  {
    label: '库存变更审核',
    value: 222
  }

]

// 商品表格
export const GoodsTable = [
  {
    title: '商家',
    fixed: 'left',
    dataIndex: 'merchant_name'
  },
  {
    title: '商品图片',
    scopedSlots: { customRender: "avatar" },
    width: 100
  },
  {
    title: '商品ID/商品名称',
    scopedSlots: { customRender: "id-name" },
    width: 150
  },
  {
    title: '商家类目',
    scopedSlots: { customRender: "category" }
  },
  {
    title: '供货价(元)',
    dataIndex: 'supply_price_range',
    width: 120
  },
  {
    title: '商家提报时间',
    scopedSlots: { customRender: "sub_audit_time" },
    width: 150
  },
  // {
  //     title: '初审时间',
  //     scopedSlots: { customRender: "audit_time" },
  //     width: 150
  // },
  {
    title: '状态',
    scopedSlots: { customRender: "spu_status" },
    width: 130
  },
  {
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: "action" },
    width: 200
  },
]

// 价格变更审核
export const GoodsPriceTable = [
  {
    title: '商家',
    dataIndex: 'merchant_name'
  },
  {
    title: '商品图片',
    scopedSlots: { customRender: "avatar" },
    width: 100
  },
  {
    title: '商品ID/商品名称',
    scopedSlots: { customRender: "id-name" }
  },
  {
    title: '商家类目',
    scopedSlots: { customRender: "category" },
    width: 150
  },
  {
    title: '申请时间',
    scopedSlots: { customRender: "audit_create_time" },
    width: 150
  },
  {
    title: '审核时间',
    scopedSlots: { customRender: "audit_time" },
    width: 150
  },
  {
    title: '审核状态',
    scopedSlots: { customRender: "audit_status" },
    width: 120
  },
  {
    title: '操作',
    scopedSlots: { customRender: "price_action" }
  },
]

// 账号管理
export const AccountTable = [
  {
    title: '商家名称',
    dataIndex: 'merchant_name'
  },
  {
    title: '账户余额',
    align: 'center',
    dataIndex: 'account_balanceFormat'
  },
  {
    title: '待对账金额',
    align: 'center',
    dataIndex: 'unreconciled_amountFormat'
  },
  {
    title: '保证金(元)',
    align: 'center',
    dataIndex: 'deposit_money'
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: "action" }
  },
]

// 小店管理
export const MerchantTable = [
  {
    title: '小店名称',
    dataIndex: 'name'
  },
  {
    title: '小店类型',
    scopedSlots: { customRender: "category" }
  },
  {
    title: '小店状态',
    scopedSlots: { customRender: "type" }
  },
  {
    title: '经营范围',
    scopedSlots: { customRender: "scope" }
  },
  {
    title: '联系电话',
    dataIndex: 'contact_mobile'
  },
  {
    title: '操作',
    scopedSlots: { customRender: "action" }
  },
]

// 订单管理列表
export const OrderTable = [
  {
    title: '商家',
    width: 1
  },
  {
    title: '子订单编号',
    width: '250px'
  },
  {
    title: '商品',
    width: '300px'
  },
  {
    title: '供货价(元)',
    width: 1
  },
  {
    title: '销售价(元)',
    width: 1
  },
  {
    title: '数量(件)',
    width: 1
  },
  {
    title: '备注',
    width: 1
  },
  {
    title: '售后',
    width: 1
  },
  {
    title: '收货信息',
    width: '250px'
  },
  {
    title: '订单状态',
    width: 1
  },
  {
    title: '付款信息',
    width: 1
  },
  {
    title: '结算状态',
    width: 1
  },
]

// sku详情
export const SkuColumn = [
  {
    title: "SKU编码",
    dataIndex: "out_sku_id",
    width: 160
  },
  {
    title: "供货价(元)",
    scopedSlots: { customRender: "supply_price" },
    width: 120
  },
  {
    title: "建议零售价(元)",
    scopedSlots: { customRender: "recommend_price" },
    width: 120
  },
  {
    title: "销售价(元)",
    scopedSlots: { customRender: "xd_price" },
    width: 140
  },
  {
    title: "未上架库存(件)",
    dataIndex: "stock_num",
    width: 140
  },
  {
    title: "在架库存(件)",
    dataIndex: "xd_stock_num",
    width: 140
  }
]

// 价格变更详情列表
export const PriceChange = [
  {
    title: 'SKU编码',
    dataIndex: 'out_sku_id'
  },
  {
    title: '当前供货价(元)',
    scopedSlots: { customRender: "pre_price" }
  },
  {
    title: '变更后供货价(元)',
    scopedSlots: { customRender: "after_price" }
  },
  {
    title: '生效时间',
    scopedSlots: { customRender: "effect_time" }
  },
]

// 库存变更后
export const StockChange = [
  {
    title: 'SKU编码',
    dataIndex: 'out_sku_id'
  },
  {
    title: '当前库存量(件)',
    dataIndex: 'current_stock_num'
  },
  {
    title: '变更库存量(件)',
    dataIndex: 'stock_num'
  }
]

// 结算状态
export const Settlement = [
  {
    label: '已结算',
    value: 1
  },
  {
    label: '未结算',
    value: 0
  },
]

export const OrderStatusInfo = [
  {
    label: '待确认',
    value: 1
  },
  {
    label: '备货中-用户已付款', // 用户已付款，此状态商户才能执行发货操作(货到付款的订单商家需要确认订单才能进入此状态)
    value: 2
  },
  {
    label: '备货中-用户取消', // 用户取消
    value: 25
  },
  {
    label: '备货中-退款商家拒绝', // 退款商家拒绝
    value: 26
  },
  {
    label: '已发货', // 商家出库、已发货
    value: 3
  },
  {
    label: '已取消', // 用户未支付并取消订单，或超时未支付后系统自动取消，或货到付款用户拒收
    value: 4
  },
  {
    label: '已完成', // 在线支付订单：商家发货后，用户收货、拒收或者15天无物流；货到付款用户确认订单
    value: 5
  },
  {
    label: '(退款)申请退款中-商家还未发货，用户申请退款', // 申请退款中:商家还未发货，用户申请退款
    value: 16
  },
  {
    label: '(退款)商户同意退款', // 商户同意退款
    value: 17
  },
  {
    label: '(退款)订单退款仲裁中', // 订单退款仲裁中
    value: 18
  },
  {
    label: '(退款)退款仲裁支持用户', // 退款仲裁支持用户
    value: 19
  },
  {
    label: '(退款)退换仲裁支持商家', // 退换仲裁支持商家
    value: 20
  },
  {
    label: '(退款)订单退款成功', // 订单退款成功：即用户在商家出库前可发起退款，最终退款成功
    value: 21
  },
  {
    label: '(退款)售后退款成功', // 售后退款成功：即用户在退款后，正常走完退货流程，退款成功
    value: 22
  },
  {
    label: '(退款中)退款申请', // 退款申请
    value: 30
  },
  {
    label: '退款申请取消',
    value: 31
  },
  {
    label: '(退款成功)商家同意', // 商家同意
    value: 32
  },
  {
    label: '(退款中)商家拒绝', // 商家拒绝
    value: 33
  },
  {
    label: '(退款中)客服仲裁', // 客服仲裁
    value: 34
  },
  {
    label: '(退款中)客服同意', // 客服同意
    value: 35
  },
  {
    label: '(退款中)退款失败', // 退款失败
    value: 36
  },
  {
    label: '(已关闭)退款失败', // 退款失败
    value: 37
  },
  {
    label: '(退款中)线下退款成功', // 线下退款成功
    value: 38
  },
  {
    label: '(退款中)退款成功', // 退款成功
    value: 39
  },
  {
    label: '(退货中)-再次用户申请', // 再次用户申请
    value: 23
  },
  {
    label: '已关闭-退货成功', // 退货成功
    value: 24
  },
  {
    label: '(退货中)-用户申请', // 用户申请
    value: 6
  },
  {
    label: '(退货中)-商家同意退货', // 商家同意退货
    value: 7
  },
  {
    label: '(退货中)-客服仲裁', // 客服仲裁
    value: 8
  },
  {
    label: '已关闭-退货失败', // 退货失败
    value: 9
  },
  {
    label: '(退货中)-客服同意', // 客服同意
    value: 10
  },
  {
    label: '(退货中)-用户填写完物流', // 用户填写完物流
    value: 11
  },
  {
    label: '(退货)已关闭-商户同意', // 商户同意
    value: 12
  },
  {
    label: '(退货中)-再次客服申请', // 再次客服申请
    value: 13
  },
  {
    label: '(退货)已关闭-客服同意', // 客服同意
    value: 14
  },
  {
    label: '(退货)取消退货申请-取消退货申请', // 取消退货申请
    value: 15
  },
  {
    label: '(退货中)商家拒绝退货', // 商家拒绝退货
    value: 27
  },
  {
    label: '退货失败',
    value: 28
  },
  {
    label: '(退货中)商家再次拒绝', // 商家再次拒绝
    value: 29
  }
]



/**
 * @Author: 矢车
 * @Date: 2020-07-31 10:22:38
 * @Description: 结算管理列表
 */
export const SettlementColumn = [
  {
    title: "商家名称",
    fixed: 'left',
    dataIndex: "merchant_name",
    align: "center",
    width: 130
  },
  {
    title: "结算单号",
    fixed: 'left',
    dataIndex: "settlement_no",
    align: "center",
    width: 170
  },
  {
    title: "结算订单数",
    dataIndex: "order_num",
    align: "center",
    width: 100
  },
  {
    title: "结算金额（元）",
    dataIndex: "settlement_amountFormat",
    align: "center",
    width: 150
  },
  {
    title: "结算单发放时间",
    dataIndex: "issue_time",
    align: "center",
    scopedSlots: { customRender: "timeStampFormat" },
    width: 160
  },
  {
    title: "发放人",
    dataIndex: "admin_user_name",
    align: "center",
    width: 120
  },
  {
    title: "对账完成时间",
    dataIndex: "reconciliation_time",
    align: "center",
    scopedSlots: { customRender: "reconciliation_time" },
    width: 160
  },
  {
    title: "对账状态",
    dataIndex: "reconciliation_status",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "reconciliation_status" }
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];


/**
 * @Author: 矢车
 * @Date: 2020-07-31 11:31:24
 * @Description: 导入结算单列表
 */
export const ToLeadSettlementColumn = [
  {
    title: "商家名称",
    dataIndex: "merchant_name",
    align: "center",
    width: 200
  },
  {
    key: 'action',
    dataIndex: "valid_order_num",
    scopedSlots: { customRender: 'action', title: 'valid_order_num' },
    align: 'center',
    width: 200
  },
  {
    key: 'action',
    dataIndex: "invalid_order_num",
    scopedSlots: { customRender: 'action', title: 'invalid_order_num' },
    align: 'center',
    width: 200
  },
  {
    title: "结算金额（元）",
    dataIndex: "settlement_amountFormat",
    align: "center",
    width: 200
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "operation" }
  }
];

/**
 * @Author: 矢车
 * @Date: 2020-07-31 14:48:48
 * @Description: 导入结算单 -> 结算明细
 */
export const ToLeadSettlementDetailColumn = [
  {
    title: "子订单编号",
    dataIndex: "xd_order_id",
    align: "center",
    width: 160
  },
  {
    title: "商品编码",
    dataIndex: "out_sku_id",
    align: "center",
    width: 160
  },
  {
    title: "商品名称",
    dataIndex: "spu_name",
    align: "center",
    width: 160
  },
  {
    title: "下单时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "order_time" }
  },
  {
    title: "商品件数",
    dataIndex: "product_num",
    align: "center",
    width: 160
  },
  {
    title: "供货金额（元）",
    dataIndex: "supply_priceFormat",
    align: "center",
    width: 160
  },
  {
    title: "总运费（元）",
    dataIndex: "express_feeFormat",
    align: "center",
    width: 150
  },
  {
    title: "结算时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "settlement_time" }
  },
  {
    title: "订单状态",
    dataIndex: "order_statusFormat",
    align: "center",
    width: 100
  },
];


/**
 * @Author: 矢车
 * @Date: 2020-07-31 14:58:48
 * @Description: 结算管理 -> 结算明细
 */
export const SettlementDetailColumn = [
  {
    title: "子订单编号",
    dataIndex: "xd_order_id",
    align: "center",
    width: 160
  },
  {
    title: "商品编码",
    dataIndex: "out_sku_id",
    align: "center",
    width: 160
  },
  {
    title: "商品名称",
    dataIndex: "spu_name",
    align: "center",
    width: 160
  },
  {
    title: "下单时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "order_time" }
  },
  {
    title: "商品件数",
    dataIndex: "product_num",
    align: "center",
    width: 160
  },
  {
    title: "供货金额（元）",
    dataIndex: "supply_priceFormat",
    align: "center",
    width: 160
  },
  {
    title: "总运费（元）",
    dataIndex: "express_fee",
    align: "center",
    width: 150
  },
  {
    title: "结算时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "settlement_time" }
  },
  {
    title: "订单状态",
    dataIndex: "order_statusFormat",
    align: "center",
    width: 100
  },
];

export const SettleList = [
  {
    title: "商家名称",
    dataIndex: "mobile",
    align: "center",
    width: 130
  },
  {
    title: "提现单号",
    dataIndex: "channelFormat",
    align: "center",
    width: 130
  },
  {
    title: "申请提现金额(元)",
    dataIndex: "nick",
    align: "center",
    width: 130
  },
  {
    title: "申请时间",
    dataIndex: "create_time",
    align: "center",
    width: 160
  },
  {
    title: "提现后账户余额",
    dataIndex: "create_time",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];


/**
 * @Author: 矢车
 * @Date: 2020-08-03 13:40:32
 * @Description: 提现待审核列表
 */
export const WithdrawStayAuditColumn = [
  {
    title: "商家名称",
    dataIndex: "merchant_name",
    align: "center",
    width: 130
  },
  {
    title: "提现单号",
    dataIndex: "withdraw_deposit_no",
    align: "center",
    width: 130
  },
  {
    title: "申请提现金额(元)",
    dataIndex: "withdraw_deposit_amountFormat",
    align: "center",
    width: 130
  },
  {
    title: "申请时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "apply_time" }
  },
  {
    title: "提现后账户余额（元）",
    dataIndex: "account_balanceFormat",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];


/**
 * @Author: 矢车
 * @Date: 2020-08-03 13:53:14
 * @Description: 提现待打款列表
 */
export const WithdrawStayPayColumn = [
  {
    title: "商家名称",
    dataIndex: "merchant_name",
    align: "center",
    width: 130
  },
  {
    title: "提现单号",
    dataIndex: "withdraw_deposit_no",
    align: "center",
    width: 130
  },
  {
    title: "申请提现金额(元)",
    dataIndex: "withdraw_deposit_amountFormat",
    align: "center",
    width: 130
  },
  {
    title: "申请时间",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "apply_time" }
  },
  {
    title: "提现后账户余额（元）",
    dataIndex: "account_balanceFormat",
    align: "center",
    width: 160
  },
  {
    title: "商家账户",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "account" }
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];


/**
 * @Author: 矢车
 * @Date: 2020-08-03 15:49:29
 * @Description: 账户管理 - 账户详情
 */
export const AccountManagementDetailColumn = [
  {
    title: "操作类型",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "event_type" }
  },
  {
    title: "结算/提现单号",
    dataIndex: "event_no",
    align: "center",
    width: 130
  },
  {
    title: "操作时间",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "history_time" }
  },
  {
    title: "账户金额变更（元）",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "amount" }
  },
  {
    title: "状态",
    dataIndex: "statusFormat",
    align: "center",
    width: 160
  },
  {
    title: "账户余额（元）",
    dataIndex: "account_balanceFormat",
    align: "center",
    width: 160
  },
  {
    title: "打款凭证",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "account" }
  },
  {
    title: "打款人",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "pay_name" }
  },
];
