<template>
  <div class="inquire-detail">
    <template v-if="isOnline">
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">支付方式：</a-col>
        <a-col :span="colContent">{{
          rowData.online_pay_result.pay_type === 2 ? '支付宝' : '微信支付'
        }}</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">商家名称：</a-col>
        <a-col :span="colContent">{{ rowData.merchant_name }}</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">保证金金额（元）：</a-col>
        <a-col>{{ rowData.deposit_money }}</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl"><strong>支付信息</strong></a-col>
      </a-row>
      <template v-if="rowData.online_pay_result.pay_type === 2">
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">支付宝交易号：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.transaction_id }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">买家支付宝账号：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.buyer_logon_id }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">订单金额（元）：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.total_fee / 100 }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">实收金额（元）：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.receipt_amount }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">交易付款时间：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.time_end }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">交易状态：</a-col>
          <a-col :span="colContent">{{ getState(rowData.online_pay_result.trade_state) }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">商品描述：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.id }}</a-col>
        </a-row>
      </template>
      <template v-else-if="rowData.online_pay_result.pay_type === 3">
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">微信支付订单号：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.transaction_id }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">现金支付金额：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.total_fee / 100 }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">付款银行：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.bank_name }}</a-col>
        </a-row>
        <a-row :gutter="rowGutter">
          <a-col :span="colLbl">支付完成时间：</a-col>
          <a-col :span="colContent">{{ rowData.online_pay_result.time_end }}</a-col>
        </a-row>
      </template>
    </template>
    <template v-else>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">支付方式：</a-col>
        <a-col :span="colContent">线下支付</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">商家名称：</a-col>
        <a-col>{{ rowData.merchant_name }}</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">保证金金额：</a-col>
        <a-col>{{ rowData.deposit_money }}</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="colLbl">保证金凭证：</a-col>
      </a-row>
      <a-row :gutter="rowGutter">
        <a-col :span="24">
          <img :src="rowData.deposit_history_info.transfer_voucher" alt="" />
        </a-col>
      </a-row>
    </template>
    <div>
      <pre class="language-json" style="display: none;">{{ JSON.stringify(rowData, null, 2) }}
      </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "KrShopAccountDetailDrawer",
  data() {
    return {
      colLbl: 8,
      colContent: 16,
      rowGutter: [0, 12],
    };
  },
  props: {
    rowData: {},
  },
  computed: {
    isOnline() {
      return this.rowData.deposit_history_info.pay_type !== 1;
    },
  },
  methods: {
    getState(code) {
      switch (code) {
        case "SUCCESS":
          return "支付成功";
        case "REFUND":
          return "转入退款";
        case "NOTPAY":
          return "未支付";
        case "CLOSED":
          return "已关闭";
        case "REVOKED":
          return "已撤销（付款码支付）";
        case "USERPAYING":
          return "用户支付中（付款码支付）";
        case "PAYERROR":
          return "支付失败";
        default:
          return "";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.inquire-detail {
  .ant-col-24 {
    margin-bottom: 20px;
  }

  .subject {
    text-align: left;
  }
}
</style>

<style scoped>
.ant-col-8 {
  width: auto;
  padding-right: 5px;
}
</style>
