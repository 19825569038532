/*
 * @Author: 肖槿
 * @Date: 2020-06-18 10:46:56
 * @Description: 组件混合函数
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-07-07 10:01:19
 * @FilePath: \s2b-admin\src\components\admin\shop\shopMixin.js
 */
import { positiveInt, checkPhone } from '@/utils/shop';

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      queryParams: {},
      positiveInt,
      checkPhone,
    };
  },
  methods: {
    formReset() {
      this.form.resetFields();
      this.queryParams = {};
      this.queryParams.page = 1;
      this.queryParams.page_size = 10;
      this.$emit('success', this.queryParams);
    },
    handleFormSubmit(e) {
      e.preventDefault();
      this.queryParams.page = 1;
      Object.keys(this.queryParams).forEach(v => {
        if (this.queryParams[v] === '') delete this.queryParams[v];
      });
      this.$emit('success', this.queryParams);
    },
    onlyNumber(e, k) {
      const val = e.target.value;
      if (!this.positiveInt(val)) {
        e.target.value = '';
        this.$set(this.queryParams, k, '');
      }
    },
    onlyPhone(e, k) {
      const val = e.target.value;
      if (!this.checkPhone(val)) {
        e.target.value = '';
        this.queryParams[k] = '';
      }
    },
    positionContainer() {
      return document.getElementById('cctv');
    },
  },
};
