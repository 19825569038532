<!--
 * @Author: 矢车
 * @Date: 2020-07-25 09:50:41
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-14 10:42:47
 * @Descripttion: 结算管理 - 结算单明细
-->
<template>
  <div>
    <div class="content">
      <div class="mb10 flex-item">
        <div class="explain">
          <span class="mr30">商家名称：{{props_rows.merchant_name}}</span>
          <span>结算金额：{{props_rows.settlement_amount/100}}元</span>
        </div>
        <div class="descript">置灰的订单代表已被结算，将不会二次结算</div>
      </div>
      <a-table :scroll="{ x: 600 ,y: tableScrollY }" :rowKey="record => record.id" :rowClassName="record=> record.settlement_status==1?'finalColor':''" :columns="ToLeadSettlementDetailColumn" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: ' 暂无数据' }" @change="handleTableChange">
        {{record}}aa
        <template slot="order_time" slot-scope="record">
          <span>{{record.order_time| dateFilter}}</span>
        </template>
        <template slot="settlement_time" slot-scope="record">
          <span>{{record.settlement_time| dateFilter}}</span>
        </template>
      </a-table>
    </div>
  </div>
  <!-- #ececec -->
</template>

<script>
import { ToLeadSettlementDetailColumn } from "@/const/shop";
import { getPreSettlementId } from "@/service/shop";

export default {
  props: ["props_rows"],
  data() {
    return {
      tableScrollY: 600,
      ToLeadSettlementDetailColumn,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-07-31 15:58:37
     * @Description: 获取列表数据
     */
    async getList() {
      const { res } = await getPreSettlementId(this.props_rows.id, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      if (res.error_code === 0) {
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            order_statusFormat: item.order_status == 1 ? "已完成" : "未完成",
            supply_priceFormat: item.supply_price / 100,
            express_feeFormat: item.express_fee / 100,
          };
        });
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
      }
    },
    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .flex-item {
    display: flex;
    justify-content: space-between;
  }

  .descript {
    font-weight: bold;
    color: red;
  }
}
</style>
<style>
.content .finalColor {
  background: #efefef;
}
</style>
