<!--
 * @Author: 肖槿
 * @Date: 2020-06-13 11:21:32
 * @Description: 商家详情
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-12 15:16:19
 * @FilePath: \s2b-admin\src\components\admin\shop\merchant-audit.vue
-->
<template>
  <div class="merchant-audit">
    <a-spin class="spin-div" :spinning="baseInfoLoading">
      <a-card size="small" v-if="type === 'detail'" title="基本信息" class="detail-card">
        <a-row>
          <a-col span="24" class="detail-item">
            <span class="label">注册手机：</span>
            <span class="content">{{ merchantData.user_mobile || '--' }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">注册时间：</span>
            <span class="content">{{
              merchantData.user_create_time | timeFormat('YYYY-MM-DD HH:mm')
            }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">最后访问：</span>
            <span class="content">{{
              merchantData.last_login_time | timeFormat('YYYY-MM-DD HH:mm')
            }}</span>
          </a-col>
          <a-col v-if="merchantData.audit_status === 3" span="24" class="detail-item">
            <span class="label">{{
              merchantData.audit_status === 4 ? '拒绝原因：' : '备注信息：'
            }}</span>
            <span
              class="content"
              style="line-height: 1.5em; word-break: break-all; line-break: anywhere"
              >{{ merchantData.audit_info.remark || '--' }}</span
            >
          </a-col>
        </a-row>
      </a-card>
      <a-card size="small" v-if="type === 'certification'" class="detail-card">
        <a-row>
          <a-col span="12" class="detail-item">
            <span class="label">注册手机：</span>
            <span class="content">{{ merchantData.user_mobile || '--' }}</span>
          </a-col>
          <a-col span="12" class="detail-item">
            <span class="label">申请时间：</span>
            <span class="content">{{
              merchantData.apply_time | timeFormat('YYYY-MM-DD HH:mm')
            }}</span>
          </a-col>
        </a-row>
      </a-card>
      <a-card size="small" class="detail-card">
        <div slot="title">
          <span>认证信息</span>
          <template v-if="type === 'detail'">
            <a-divider type="vertical" />
            <span :class="merchantData.audit_status | certificationClass">{{
              merchantData.audit_status | shopMargin(Certification)
            }}</span>
          </template>
        </div>
        <template
          v-if="(type === 'detail' && merchantData.audit_status === 3) || type === 'certification'"
        >
          <!-- [营业执照信息] -->
          <a-card size="small" title="营业执照信息" :bordered="false">
            <a-row>
              <a-col span="24" class="detail-item">
                <span class="label">公司名称：</span>
                <span class="content">{{ merchantData.merchant_name || '--' }}</span>
              </a-col>
              <a-col span="24" class="detail-item">
                <span class="label">主营业务：</span>
                <span class="content">{{ shopScope || '--' }}</span>
              </a-col>
              <a-col span="24" class="detail-item">
                <span class="label">经营地址：</span>
                <span class="content"
                  >{{ merchantData.business_province_id | division
                  }}{{ merchantData.business_city_id | division
                  }}{{ merchantData.business_district_id | division
                  }}{{ merchantData.business_address }}</span
                >
              </a-col>
              <a-col span="24" class="detail-item">
                <span class="label">相关证件：</span>
                <!--                <span class="content">{{
                  merchantData.license_type === 2 ? '三证合一' : '常规'
                }}</span>-->
                <div class="company-auth">
                  <div class="prove" v-if="merchantData.license_list">
                    <p v-for="(v, index) in merchantData.license_list" :key="v.id">
                      <!--                      <img :src="v.img_url" />-->
                      <my-image
                        :list-url="(merchantData.license_list || []).map(item => item.img_url)"
                        :select-index="index"
                        :imgWidth="150"
                        :imgUrl="v.img_url"
                      />
                      <span>{{ v.img_type | shopMargin(LicenseType) }}</span>
                    </p>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
          <!--          &lt;!&ndash; [法人信息] &ndash;&gt;
          <a-card size="small" title="法人信息" :bordered="false">
            <a-row>
              <a-col span="24" class="detail-item">
                <span class="label">法人姓名：</span>
                <span class="content">{{ merchantData.juridical_person || '&#45;&#45;' }}</span>
              </a-col>
              <a-col span="24" class="detail-item">
                <span class="label">法人身份证：</span>
                <div class="company-idcard">
                  <viewer
                    :images="merchantData.juridical_person_id_card_list"
                    class="prove"
                    v-if="merchantData.juridical_person_id_card_list"
                  >
                    <p v-for="v in merchantData.juridical_person_id_card_list" :key="v.id">
                      <img :src="v.img_url" />
                      <span>{{ v.img_type | shopMargin(LicenseType) }}</span>
                    </p>
                  </viewer>
                </div>
              </a-col>
            </a-row>
          </a-card>-->
          <!-- [开户信息] -->
          <!--          <a-card size="small" title="开户信息" :bordered="false">-->
          <!--            <a-row>-->
          <!--              <a-col span="24" class="detail-item">-->
          <!--                <span class="label">收款账号开户行：</span>-->
          <!--                <span class="content">{{ merchantData.deposit_bank_info.bank_name }}</span>-->
          <!--              </a-col>-->
          <!--              <a-col span="24" class="detail-item">-->
          <!--                <span class="label">收款账号：</span>-->
          <!--                <span class="content">{{ merchantData.deposit_bank_info.account_number }}</span>-->
          <!--              </a-col>-->
          <!--            </a-row>-->
          <!--          </a-card>-->
          <a-card size="small" title="店铺信息" :bordered="false">
            <a-row>
              <a-col span="24" class="detail-item shop">
                <span class="label">店铺名称：</span>
                <div class="content">
                  <div class="shop-div">
                    <div>{{ merchantData.shop_name || '--' }}</div>
                    <div class="link" v-if="this.merchantData.spu_url" @click="gotoShopClick">
                      <a-icon type="link" />
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col span="12" class="detail-item">
                <span class="label">店铺口碑分：</span>
                <span class="content">{{ merchantData.shop_score || '--' }}</span>
              </a-col>
              <a-col span="12" class="detail-item">
                <span class="label">店铺体验分：</span>
                <span class="content">{{ merchantData.service_score || '--' }}</span>
              </a-col>
              <a-col span="24" class="detail-item img">
                <span class="label">店铺评分截图：</span>
                <div class="content">
                  <my-image :imgWidth="100" :imgUrl="merchantData.shop_score_pic" />
                </div>
              </a-col>
            </a-row>
          </a-card>
          <!-- [品牌信息] -->
          <a-card
            size="small"
            title=""
            :bordered="false"
            v-if="merchantData.brand_info || merchantData.brand_info_list"
            style="border-top: 1px solid #e8e8e8"
          >
            <a-row>
              <a-col span="24" class="detail-item">
                <span class="label">品牌名称：</span>
                <span v-if="merchantData.brand_info" class="content">{{
                  merchantData.brand_info.brand_name
                }}</span>
                <template v-if="merchantData.brand_info_list">
                  <span
                    v-for="item in merchantData.brand_info_list"
                    :key="item.brand_name"
                    class="content"
                    >{{ item.brand_name }}</span
                  >
                </template>
              </a-col>
              <a-col span="24" class="detail-item">
                <span class="label">商家类型：</span>
                <span class="content">
                  <span v-if="merchantData.brand_type === 1">品牌方</span>
                  <span v-else-if="merchantData.brand_type === 2">代理商</span>
                  <span v-else-if="merchantData.brand_type === 3">黑标</span>
                  <span v-else>--</span>
                </span>
              </a-col>
              <a-col v-if="merchantData.brand_type" span="24" class="detail-item img">
                <span class="label">
                  <span v-if="merchantData.brand_type === 1">商标证书：</span>
                  <span v-else-if="merchantData.brand_type === 2">授权证书：</span>
                  <span v-else-if="merchantData.brand_type === 3">店铺截图：</span>
                </span>
                <div class="content swiper-div">
                  <div
                    v-for="(item, index) in merchantData.brand_credential_list || []"
                    :key="item.id"
                  >
                    <my-image
                      :list-url="
                        (merchantData.brand_credential_list || []).map(item => item.img_url)
                      "
                      :select-index="index"
                      :imgWidth="88"
                      :imgUrl="item.img_url"
                    />
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
          <!-- [业务联系人] -->
          <a-card size="small" title="" :bordered="false" style="border-top: 1px solid #e8e8e8">
            <a-row>
              <a-col span="24" class="detail-item">
                <span class="label">对接联系人电话：</span>
                <span class="content">{{ contact_info.contact_tel }}</span>
              </a-col>
            </a-row>
          </a-card>
        </template>
      </a-card>
      <a-card size="small" v-if="type === 'certification'" title="审核结果" class="detail-card">
        <a-row>
          <a-col span="24" class="detail-item">
            <a-radio-group v-model="audit_status">
              <a-radio :value="3">通过</a-radio>
              <a-radio :value="4">拒绝</a-radio>
            </a-radio-group>
            <div class="certification-area">
              <a-textarea
                v-model="msg"
                :placeholder="placeText"
                :auto-size="{ minRows: 3 }"
                :max-length="500"
              />
            </div>
          </a-col>
        </a-row>
      </a-card>
    </a-spin>
    <div class="certification-btn">
      <a-button style="margin-right: 30px" @click="closeHandler">取消</a-button>
      <a-button type="primary" @click="subCertification">提交</a-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { getMerchantInfoByUserId, getMerchantHistoryInfo } from '@/service/shop';
import { Certification, LicenseType, Brand } from '@/const/shop';
import { mapGetters } from 'vuex';
import { brandValidityPeriodRender, agentValidityPeriodRender } from '@/tslibs/utils/format';

export default Vue.extend({
  name: 'MerchantAudit',
  props: {
    type: {
      type: String,
      default: 'detail',
    },
  },
  data() {
    return {
      baseInfoLoading: true,
      audit_status: '',
      msg: '',
      merchantData: {
        main_business_list: [],
        license_list: [],
        juridical_person_id_card_list: [],
        brand_credential_list: [],
        deposit_bank_info: {},
        audit_info: {},
        contact_info: {},
      },
      Certification,
      LicenseType,
      Brand,
    };
  },
  computed: {
    ...mapGetters(['getUserinfo']),
    shopScope() {
      const l = this.merchantData.main_business_list
        ? this.merchantData.main_business_list.map(v => v.cat_name)
        : '';
      return l.length ? l.join('，') : '';
    },
    placeText() {
      return this.audit_status === 4
        ? '拒绝原因'
        : this.audit_status === 3
        ? '备注信息'
        : '请选择审核结果';
    },
    // 业务联系人信息防无数据卡界面计算属性
    contact_info() {
      return this.merchantData.contact_info ?? {};
    },
  },
  methods: {
    gotoShopClick() {
      if (this.merchantData.spu_url) {
        window.open(this.merchantData.spu_url);
      }
    },
    brandValidityPeriodRender,
    agentValidityPeriodRender,
    async show(row) {
      this.baseInfoLoading = true;
      const res = await getMerchantInfoByUserId(`${row.user_id}/${row.id || 0}/`);
      this.baseInfoLoading = false;
      this.merchantData = {
        ...{
          main_business_list: [],
          license_list: [],
          juridical_person_id_card_list: [],
          brand_credential_list: [],
          deposit_bank_info: {},
          audit_info: {},
          contact_info: {},
          brand_info_list: [],
          brand_info: {},
        },
        ...res.res.data,
      };
    },
    subCertification() {
      const obj = {
        audit_status: this.audit_status,
        id: this.merchantData.id,
        audit_user: this.getUserinfo.id,
        audit_user_name: this.getUserinfo.username,
      };

      if (this.audit_status === 3) {
        obj.remark = this.msg;
      } else if (this.audit_status === 4) {
        obj.failed_reason = this.msg;
        if (!obj.failed_reason.trim()) {
          this.$message.error('拒绝原因不能为空');
          return;
        }
      } else {
        this.$message.error('请选择审核结果');
        return;
      }
      this.$emit('certification', obj);
    },
    closeHandler() {
      this.$emit('close');
    },
    hide() {
      this.msg = '';
      this.audit_status = '';
    },
  },
});
</script>

<style lang="less" scoped>
.merchant-audit {
  .spin-div {
    max-height: calc(100vh - 170px);
    padding-right: 12px;
    padding-bottom: 12px;
    overflow: auto;
  }
  & .detail-card {
    margin-top: 20px;
    & .detail-item {
      line-height: 40px;
      &.shop {
        .content {
          display: inline-block;
          width: 400px;
          .shop-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            div:first-child {
              display: inline-block;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .link {
              display: inline-block;
              width: 60px;
              margin-left: 12px;
              cursor: pointer;
              &:hover {
                color: #e83741;
              }
            }
          }
        }
      }
      &.img {
        display: flex;
        .content {
          width: 400px;
        }
        .swiper-div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          > div {
            margin-right: 12px;
            margin-bottom: 12px;
          }
        }
      }
      & .label {
        display: inline-block;
        width: 120px;
        text-align: right;
        font-weight: 900;
        color: #000;
      }
      & .content {
        display: inline-block;
        margin-right: 10px;
      }
    }
    & .company-auth {
      margin-left: 120px;
      & > .prove p {
        display: inline-block;
        text-align: center;
        width: 150px;
        margin-right: 50px;
        & img {
          width: 100%;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
    & .company-idcard {
      display: inline-block;
      vertical-align: top;
      & > .prove p {
        display: inline-block;
        text-align: center;
        width: 200px;

        & img {
          width: 100%;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      & .prove p:last-child {
        margin-left: 50px;
      }
    }
    & .company-brand {
      margin-left: 130px;
      & > .prove p {
        display: inline-block;
        text-align: center;
        width: 200px;
        margin-right: 50px;
        & img {
          width: 100%;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
    & .certification-area {
      margin-top: 10px;
    }
  }
  & .detail-card:first-child {
    margin-top: 0;
  }
  .certification-btn {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
