<!--
 * @Author: 矢车
 * @Date: 2020-07-25 09:50:41
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-17 17:00:25
 * @Descripttion: 结算管理 - 结算明细
-->
<template>
  <div>
    <div class="content" >
      <div class="explain mb10 flex-item">
        <ul>
          <li>商家名称：{{props_row.merchant_name}}</li>
          <li>结算单号：{{props_row.settlement_no}}</li>
          <li>结算金额：{{props_row.settlement_amount/100}}元</li>
          <li>结算单发放时间：{{props_row.issue_time | dateFilter}}</li>
          <li>发放人：{{props_row.admin_user_name}}</li>
          <li>对账完成时间：{{(props_row.reconciliation_time | dateFilter) || '--'}}</li>
        </ul>
        <a-button type="primary" @click="exportStatement">导出明细</a-button>
      </div>
      <a-table :scroll="{ x: 600 ,y: tableScrollY }" :rowKey="record => record.id" :columns="SettlementDetailColumn" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
        <template slot="order_time" slot-scope="record">
          {{record.order_time | dateFilter }}
        </template>
        <template slot="settlement_time" slot-scope="record">
          {{record.settlement_time | dateFilter }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { SettlementDetailColumn } from "@/const/shop";
import { getSettlementId, getSettleExport } from "@/service/shop";
import { baseURL } from "@/config";

export default {
  props: ["props_row"],
  data() {
    return {
      tableScrollY: 600,
      isSpinShow: false,
      baseURL,
      SettlementDetailColumn,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  mounted() {
    this.getList();
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-07-31 16:58:37
     * @Description: 获取列表数据
     */
    async getList() {
      const { res } = await getSettlementId(this.props_row.id, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      if (res.error_code == 0) {
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            order_statusFormat: item.order_status == 1 ? "已完成" : "未完成",
            supply_priceFormat: item.supply_price / 100,
          };
        });
      }
      const pagination = { ...this.pagination };
      pagination.total = res.data.total;
      this.pagination = pagination;
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-09 10:03:57
     * @Description: 导出结算单功能实现
     */
    async exportStatement() {
      this.$emit("showSpin", true);
      const { res } = await getSettleExport(this.props_row.id, {
        responseType: "blob",
        timeout: 120000,
      });
      if (res) {
        const link = document.createElement("a");
        let blob = new Blob([res], {
          type: "application/vnd.ms-excel",
        });
        link.style.display = "none";
        //设置链接
        link.href = URL.createObjectURL(blob);
        // 生成的名字
        link.setAttribute(
          "download",
          `折一货款结算明细${this.props_row.settlement_no}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$emit("showSpin", false);
      } else {
        this.$emit("showSpin", false);
      }
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .flex-item {
    display: flex;
    justify-content: space-between;
  }
  .explain {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 350px;
        line-height: 35px;
      }
    }
  }

  .descript {
    font-weight: bold;
    color: red;
  }
}
</style>


