<!--
 * @Author: 肖槿
 * @Date: 2020-06-13 14:40:46
 * @Description:
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-07-01 19:15:30
 * @FilePath: \s2b-admin\src\components\admin\shop\merchant-review-search.vue
-->
<template>
  <div>
    <a-form layout="inline" :form="form" @submit="handleFormSubmit">
      <div class="filter-condition-form">
          <a-form-item label="公司名称：">
            <a-input v-model="queryParams.merchant_name" allowClear placeholder="请输入公司名称" style="width:190px;" />
          </a-form-item>
          <a-form-item label="店铺名称：">
            <a-input v-model="queryParams.shop_name" allowClear placeholder="请输入店铺名称" style="width:190px;" />
          </a-form-item>
        <a-form-item label="商家类型：">
          <a-select v-model="queryParams.brand_type" placeholder="全部" allowClear style="width:190px;">
            <a-select-option v-for="(item, k) in merchantTypeOptions" :key="k" :value="item.value">{{item.label}}</a-select-option>
          </a-select>
        </a-form-item>
          <a-form-item label="注册手机号：">
            <a-input :maxLength="11" v-model="queryParams.user_mobile" allowClear placeholder="请输入注册手机号" style="width:190px;" @blur="onlyPhone($event, 'user_mobile')" />
          </a-form-item>
          <a-form-item v-if="tabIdx === '2'" label="审核结果：">
            <a-select v-model="queryParams.audit_status" placeholder="全部" allowClear style="width:190px;">
              <a-select-option v-for="(item, k) in AuditResult" :key="k" :value="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="loading"
              style="margin-right: 12px;"
            >查询</a-button>
            <a-button :loading="loading" @click="formReset">重置</a-button>
          </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import { AuditResult } from "@/const/shop";
import shopMixin from './shopMixin'
export default {
  mixins: [shopMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tabIdx: {
        type: String,
        default: '1'
    }
  },
  data() {
    return {
      AuditResult,
      merchantTypeOptions: [
        {
          label: '品牌方',
          value: 1
        },
        {
          label: '代理商',
          value: 2
        },
        {
          label: '黑标',
          value: 3
        },]
    };
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.filter-condition-form{
    margin-bottom: 12px;
}
</style>
