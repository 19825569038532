<!--
 * @Author: 肖槿
 * @Date: 2020-06-15 15:54:45
 * @Description: 小店管理搜索组件
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-06-18 10:53:17
 * @FilePath: \s2b-admin\src\components\admin\shop\shop-management-search.vue
-->
<template>
  <div>
    <a-form layout="inline" :form="form" @submit="handleFormSubmit">
      <div class="filter-condition-form">
        <a-form-item label="小店名称：">
          <a-input
            v-model="queryParams.name"
            allowClear
            placeholder="请输入商家名称"
            style="width:190px;"
          />
        </a-form-item>
        <a-form-item label="小店类型：">
          <a-select
            v-model="queryParams.shop_type"
            placeholder="全部"
            allowClear
            style="width:190px;"
          >
            <a-select-option v-for="(item, k) in Brand" :key="k" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="小店状态：">
          <a-select
            v-model="queryParams.shop_status"
            placeholder="全部"
            allowClear
            style="width:190px;"
          >
            <a-select-option v-for="(item, k) in Enabled" :key="k" :value="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :loading="loading"
            style="margin-right: 20px;"
            @click="handleFormSubmit"
            >查询</a-button
          >
          <a-button :loading="loading" @click="formReset">重置</a-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import { Brand, Enabled } from '@/const/shop';
import shopMixin from './shopMixin';
export default {
  mixins: [shopMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Brand,
      Enabled,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
