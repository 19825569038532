<template>
  <div class="form-div">
    <a-form  layout="inline" :form="form" @submit="handleFormSubmit">
      <div class="filter-condition-form">
          <a-form-item label="注册手机号：">
            <a-input :maxLength="11" v-model="queryParams.user_mobile" allowClear placeholder="请输入注册手机号" style="width:180px;" />
          </a-form-item>
<!--          <a-form-item label="商家认证：">
            <a-select v-model="queryParams.audit_status" placeholder="全部" allowClear style="width:180px;">
              <a-select-option v-for="(item, k) in Certification" :key="k" :value="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="保证金：">
            <a-select v-model="queryParams.pay_deposit_status" placeholder="全部" allowClear style="width:180px;">
              <a-select-option v-for="(item, k) in Margin" :key="k" :value="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="品牌类型：">
            <a-select v-model="queryParams.brand_type" placeholder="全部" allowClear style="width:180px;">
              <a-select-option v-for="(item, k) in Brand" :key="k" :value="item.value">{{item.label}}</a-select-option>
            </a-select>
          </a-form-item>
       -->
          <a-form-item label="商家名称：">
            <a-input  v-model="queryParams.merchant_name" allowClear placeholder="请输入商家名称" style="width:180px;" />
          </a-form-item>
        <a-form-item label="对接招商：">
          <a-input
            v-model.trim="queryParams.contact_username"
            allowClear
            placeholder="请输入招商人员"
            style="width: 190px"
            @keypress.enter.native="reload"
          />
        </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="loading"
              style="margin-right: 12px;"
            >查询</a-button>
            <a-button :loading="loading" @click="formReset">重置</a-button>
          </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>

import { Certification, Margin, Brand } from "@/const/shop";
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      Certification,
      Margin,
      Brand,
      queryParams: {}
    };
  },
  mounted() {},
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.$emit("success", this.queryParams);
    },
    formReset() {
      this.form.resetFields();
      this.queryParams = this.form.getFieldsValue();
      this.$emit("success", this.queryParams);
    }
  }
};
</script>

<style lang="less" scoped>
.form-div {
  padding-bottom: 4px;
}
</style>
