<!--
 * @Author: 矢车
 * @Date: 2020-07-24 10:53:53
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-17 18:43:11
 * @Descripttion: 账户详情
--> 

<template>
  <div>
    <div class="header_info">
      <a-row>
        <a-col :span="6">商家名称：{{propsRecord.merchant_name}}</a-col>
        <a-col :span="6">账户余额：{{propsRecord.account_balance/100}}元</a-col>
        <a-col :span="6">待对账金额：{{propsRecord.unreconciled_amount/100}}元</a-col>
        <a-col :span="6">保证金：{{propsRecord.deposit_money}}元</a-col>
      </a-row>
    </div>
    <a-table v-auth="'account_manage_list'" :rowKey="record => record.id" :columns="AccountManagementDetailColumn" :dataSource="list" :pagination="pagination" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="history_time" slot-scope="record">{{record.history_time | dateFilter}}</template>
      <template slot="event_type" slot-scope="record">{{record.event_type==1?'对账':'提现'}}</template>
      <template slot="amount" slot-scope="record">
        <span :class="record.transfer_type==1?'changeGreen':'changeRed'">{{record.transfer_type==1?' +':' -'}}{{record.amount/100}}</span>
      </template>
      <template slot="pay_name" slot-scope="record"> {{record.withdraw_deposit_apply_remit_info && record.withdraw_deposit_apply_remit_info.admin_user_name}} </template>
      <template slot="account" slot-scope="record">
        <ul class="payVouchers" v-if="record.event_type==2 && record.status==5">
          <li v-for="item in record.withdraw_deposit_apply_remit_info && record.withdraw_deposit_apply_remit_info.vouchers" :key="item.id">
            <viewer>
              <img :src="item.img_url" alt="">
            </viewer>
          </li>
        </ul>
        <div v-else-if="record.status==2"> -- </div>
      </template>
    </a-table>

  </div>
</template>

<script>
import { AccountManagementDetailColumn } from "@/const/shop";
import { getBalanceDetails } from "@/service/shop";

export default {
  props: ["propsRecord"],
  data() {
    return {
      AccountManagementDetailColumn,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-08-03 14:10:18
     * @Description: 获取详情数据
     */
    async getDetail() {
      const { res } = await getBalanceDetails(this.propsRecord.account_id, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });

      if (res.error_code == 0) {
        this.list = res.data.rows.map((item) => {
          let statusFormat = "待处理";
          switch (item.status) {
            case 1:
              statusFormat = "处理中";
              break;
            case 2:
              statusFormat = "处理中";
              break;
            case 3:
              statusFormat = "处理中";
              break;
            case 4:
              statusFormat = "处理中";
              break;
            case 5:
              statusFormat = "已完成";
              break;
          }
          return {
            ...item,
            statusFormat,
            account_balanceFormat: item.account_balance / 100,
          };
        });
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
      }
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getDetail();
    },
  },
};
</script>

<style lang="less" scoped>
.header_info {
  line-height: 40px;
  margin-bottom: 15px;
}
.payVouchers {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  li {
    margin: 0 5px;

    img {
      width: 70px;
      height: 70px;
    }
  }
}
.changeRed {
  color: red;
}
.changeGreen {
  color: green;
}
</style>
