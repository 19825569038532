<!--
 * @Author: 肖槿
 * @Date: 2020-06-22 09:49:14
 * @Description: 价格与库存变更详情
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-06-28 16:17:38
 * @FilePath: \s2b-admin\src\components\admin\shop\price-change.vue
-->
<template>
  <div class="price-stock-change">
    <h3 v-if="[1, 2].includes(supplyPriceData.audit_status)">
      <span style="font-size: 18px">审核结果</span>
      <a-divider type="vertical" />
      <a-tag v-if="supplyPriceData.audit_status === 1" color="green">通过</a-tag>
      <a-tag v-if="supplyPriceData.audit_status === 2" color="red">不通过</a-tag>
      <div>
        <span>审核人：</span>
        <span>{{ supplyPriceDataInfo.audit_user_name }}</span>
      </div>
      <div>
        <span>审核时间：</span>
        <span>{{ supplyPriceDataInfo.update_time | timeFormat('YYYY-MM-DD HH:mm') }}</span>
      </div>
    </h3>
    <a-spin :spinning="loading">
      <div class="change-header head">
        <div class="change-header-item">商家</div>
        <div class="change-header-item">商品图片</div>
        <div class="change-header-item">商品ID/商品名称</div>
        <div class="change-header-item">商品类目</div>
        <div class="change-header-item">申请时间</div>
      </div>
      <div class="change-body body">
        <div class="change-header-item">{{ supplyPriceData.merchant_name }}</div>
        <div class="change-header-item">
          <img id="figurImg" :src="supplyPriceData.shuffling_figure_url" alt class="avatar" />
        </div>
        <div class="change-header-item">
          <div>
            <p>{{ supplyPriceData.out_spu_id }}</p>
            <p style="color: #2db7f5">{{ supplyPriceData.spu_name }}</p>
          </div>
        </div>
        <div class="change-header-item">
          {{ supplyPriceData.first_cat_id | categoryFilter(allCategoryList) }}/{{
            supplyPriceData.second_cat_id | categoryFilter(allCategoryList)
          }}/{{ supplyPriceData.third_cat_id | categoryFilter(allCategoryList) }}
        </div>
        <div class="change-header-item">
          {{ supplyPriceDataInfo.create_time | timeFormat('YYYY-MM-DD HH:mm') }}
        </div>
      </div>
      <div v-if="supplyPriceData.audit_status === 0" style="text-align: right; margin-top: 20px">
        <a-popconfirm title="确定通过吗？" ok-text="是" cancel-text="否" @confirm="confirm(1)">
          <a-button size="small" type="primary" style="margin-right: 20px">通过</a-button>
        </a-popconfirm>
        <a-popconfirm title="确定拒绝吗？" ok-text="是" cancel-text="否" @confirm="confirm(2)">
          <a-button size="small">拒绝</a-button>
        </a-popconfirm>
      </div>
      <a-table
        style="margin-top: 20px"
        :columns="currentTab === 111 ? PriceChange : StockChange"
        :dataSource="supplyPriceDataInfo.audit_detail"
        :locale="{ emptyText: '暂无信息' }"
        :pagination="false"
      >
        <template slot="effect_time" slot-scope="row">{{
          row.effect_time | timeFormat('YYYY-MM-DD HH:mm')
        }}</template>
        <template slot="pre_price" slot-scope="row">{{ row.pre_price | thousandsFormat }}</template>
        <template slot="after_price" slot-scope="row">
          <span v-if="row.after_price === 0">--</span>
          <span v-else>
            {{ row.after_price | thousandsFormat }}
          </span>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import Viewer from 'viewerjs';
import {
  getSkuSupplyPriceInfo,
  putSkuSupplyPrice,
  getSkuSupplyStockInfo,
  putSkuSupplyStock,
} from '@/service/shop';
import { PriceChange, StockChange } from '@/const/shop';
import { mapGetters } from 'vuex';
export default {
  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
    allCategoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      supplyPriceData: {},
      supplyPriceDataInfo: {
        audit_detail: [],
      },
      loading: true,
      getSkuSupplyPriceInfo,
      getSkuSupplyStockInfo,
      StockChange,
      putSkuSupplyPrice,
      putSkuSupplyStock,
      PriceChange,
    };
  },
  computed: {
    ...mapGetters(['getUserinfo']),
  },
  methods: {
    async show(row) {
      this.supplyPriceData = row;
      this.loading = true;
      let res = null;
      if (this.currentTab === 111) {
        res = await this.getSkuSupplyPriceInfo({
          audit_group_id: row.audit_group_id,
        });
      } else {
        res = await this.getSkuSupplyStockInfo({
          audit_group_id: row.audit_group_id,
        });
      }
      this.loading = false;
      this.supplyPriceDataInfo = res.res.data;
      this.supplyPriceDataInfo.audit_detail = this.supplyPriceDataInfo.audit_detail || [];
      this.supplyPriceDataInfo.audit_detail.forEach(v => {
        v.effect_time = this.supplyPriceDataInfo.effect_time;
      });
      const viewer1 = new Viewer(document.getElementById('figurImg'), {
        navbar: false,
        toolbar: false,
        hidden() {
          viewer1.destroy();
        },
      });
      document.getElementById('figurImg').onclick = () => viewer1.show();
    },
    hide() {
      this.loading = true;
      this.supplyPriceData = {};
      this.supplyPriceDataInfo = {
        audit_detail: [],
      };
    },
    async confirm(type) {
      const params = {
        audit_group_id: this.supplyPriceData.audit_group_id,
        audit_status: type,
        audit_user_id: this.getUserinfo.id,
        audit_user_name: this.getUserinfo.username,
      };
      let res = null;
      if (this.currentTab === 111) {
        res = await this.putSkuSupplyPrice(params);
      } else {
        res = await this.putSkuSupplyStock(params);
      }
      if (res.res.success) {
        this.$message.success('提交成功');
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.price-stock-change {
  .change-header,
  .change-body {
    display: flex;

    justify-content: space-between;
    border: 1px solid #999;
    .change-header-item {
      flex: 1;
      font-weight: 900;
      border-right: 1px solid #999;
      padding: 0 10px;
    }
    .avatar {
      width: 50px;
    }
    & .change-header-item:nth-child(3) {
      flex: 1.5;
    }
    & .change-header-item:nth-child(5) {
      border-right: 0;
    }
  }
  & .head {
    margin-top: 20px;
  }
  & .change-body.body {
    border-top: 0;
  }
  & .change-body {
    & .change-header-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      p {
        margin: 0;
      }
    }
  }
}
</style>
