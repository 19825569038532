<!--
 * @Author: 肖槿
 * @Date: 2020-06-13 16:23:22
 * @Description: 商品管理搜索组件
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-07-01 10:36:56
 * @FilePath: \s2b-admin\src\components\admin\shop\goods-management-search.vue
-->
<template>
  <div>
    <a-form layout="inline" :form="form" @submit="handleFormSubmit">
      <div class="filter-condition-form">
        <a-form-item label="商家名称：">
          <a-input
            v-model="queryParams.merchant_name"
            allowClear
            placeholder="请输入商家名称"
            style="width: 190px"
          />
        </a-form-item>
        <a-form-item label="商品ID：">
          <a-input
            :maxLength="30"
            v-model="queryParams.out_spu_id"
            allowClear
            placeholder="请输入商品ID"
            style="width: 190px"
            @blur="onlyNumber($event, 'out_spu_id')"
          />
        </a-form-item>
        <a-form-item label="商品名称：">
          <a-input
            v-model="queryParams.spu_name"
            allowClear
            placeholder="请输入商品名称"
            style="width: 190px"
          />
        </a-form-item>
        <a-form-item label="商品类目：">
          <div id="cctv" style="position: relative">
            <a-cascader
              style="width: 250px"
              :load-data="loadData"
              :getPopupContainer="positionContainer"
              v-model="third_cat_id"
              :options="categoryList"
              :fieldNames="fieldNames"
              placeholder="一级类目名称 > 二级类目名称 > 三级类目名称"
              @change="onChange"
            />
          </div>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" :loading="loading" style="margin-right: 20px"
            >查询</a-button
          >
          <a-button :loading="loading" @click="formReset">重置</a-button>
        </a-form-item>
      </div>
    </a-form>
  </div>
</template>

<script>
import { AuditResult, Brand } from '@/const/shop';
import shopMixin from './shopMixin';

export default {
  mixins: [shopMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    currentTab: {
      type: Number,
      default: 1,
    },
    categoryList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      AuditResult,
      Brand,
      third_cat_id: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children',
        isLeaf: 'cat_level',
      },
    };
  },
  methods: {
    loadData(selectedOptions) {
      this.$emit('loadData', selectedOptions);
    },
    onChange(v) {
      this.queryParams.third_cat_id = v.length === 3 ? v[2] : undefined;
    },
    formReset() {
      this.form.resetFields();
      this.third_cat_id = [];
      this.queryParams.page = 1;
      this.queryParams.page_size = 10;
      this.queryParams = this.form.getFieldsValue();
      this.$emit('success', this.queryParams);
    },
  },
};
</script>

<style lang="less" scoped>
.filter-condition-form {
  margin-bottom: 20px;
}
</style>
