/*
 * @Author: 肖槿
 * @Date: 2020-06-20 11:40:01
 * @Description: 商品类型检查
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-06 19:17:49
 * @FilePath: \s2b-admin\src\utils\shop.js
 */

// 验证正数，小数点最多2位
export const amountCheck = (val) => {
  const reg = /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/
  return reg.test(val)
}

// 验证正整数
export const positiveInt = (val) => {
  const reg = /^[+]{0,1}(\d+)$/
  return reg.test(val)
}

// 验证座机
export const checkLandline = (val) => {
  const reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
  return reg.test(val)
}

// 验证手机
export const checkPhone = (val) => {
  const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
  return reg.test(val)
}


// 判断当前权限
import store from '@/store/index'
export const permiss = (val) => {
  //  如果没有，则返回false
  if (!store.state.user.rights.includes(val)) {
    return false
  } else {
    return true
  }
}
