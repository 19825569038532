<!--
 * @Author: 矢车
 * @Date: 2020-07-24 10:24:15
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 17:42:12
 * @Descripttion: 账户管理 - 提现待审核
-->

<template>
  <div>
    <div class="header_info">
      <a-form layout="inline" :form="form">
        <div class="filter-condition-form">
          <a-form-item label="商家名称：">
            <a-input v-model="searchParams.merchant_name" allowClear placeholder="请输入商家名称" style="width:190px;" />
          </a-form-item>
          <a-form-item label="提现单号：">
            <a-input v-model="searchParams.withdraw_deposit_no" allowClear placeholder="请输入提现单号" style="width:190px;" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleSearch" html-type="submit" :loading="listLoading" style="margin-right:20px;">查询</a-button>
            <a-button @click="handleReset" :loading="listLoading">重置</a-button>
          </a-form-item>
        </div>
      </a-form>
    </div>

    <a-table v-auth="'account_manage_list'" :rowKey=" record=> record.id" :columns="WithdrawStayAuditColumn" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="apply_time" slot-scope="record">{{record.apply_time | dateFilter}}</template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="drawerDetail(record)" v-auth="'shop_account_manage_examine_apply'">审核</a-button>
      </template>
    </a-table>

    <template>
      <a-drawer :destroyOnClose="true" title="审核提现申请" :width="600" :visible="drawerAuditDetail.isShow" :body-style="{ paddingBottom: '80px' }" @close="onDetailClose">
        <!-- <withdrawStayAuditDetail></withdrawStayAuditDetail> -->
        <div class="header_info">
          <a-row>
            <a-col class="title">提现信息</a-col>
            <a-col>提现金额：{{details.withdraw_deposit_amount/100}}</a-col>
            <a-col>提现结算单号：{{details.withdraw_deposit_no}}</a-col>
          </a-row>
        </div>

        <div class="content">
          <div>
            <p class="title mt30">开票凭证</p>
            <ul class="">
              <li v-for="item in details.voucher_img_list" :key="item.id">
                <viewer>
                  <img :src="item.img_url" alt="">
                </viewer>
              </li>
            </ul>
          </div>

          <div>
            <p class="title mt30">加盖公章结算单</p>
            <ul>
              <li v-for="item in details.settlement_img_list" :key="item.id">
                <p>{{item.settlement_no}}</p>
                <viewer>
                  <img :src="item.img_url" alt="">
                </viewer>
              </li>
            </ul>
          </div>

          <div>
            <p class="title mt30">审核结果</p>
            <a-radio-group v-model="drawerAuditDetail.radioStatus" name="radioGroup">
              <a-radio :value="4">通过</a-radio>
              <a-radio :value="3">拒绝</a-radio>
            </a-radio-group>
            <a-textarea v-model="refuseResult" v-if="drawerAuditDetail.radioStatus == '3'" style="margin-top: 10px" :maxLength="500" placeholder="拒绝理由不超过500字" :rows="6" />
            <div class="btn-operate">
              <a-button @click="drawerAuditDetail.isShow=false" style="margin-right: 20px">取消</a-button>
              <a-button type="primary" @click="submit">提交</a-button>
            </div>
          </div>
        </div>
      </a-drawer>

    </template>
  </div>
</template>

<script>
import { WithdrawStayAuditColumn } from "@/const/shop";
import {
  getWithdrawDepositApply,
  getWithdrawDepositApplyDetails,
  putWithdrawDepositApply,
} from "@/service/shop";

export default {
  data() {
    return {
      listLoading: false,
      form: this.$form.createForm(this),
      WithdrawStayAuditColumn,
      list: [],
      record: {}, // 当前点击的表格数据
      details: {},
      refuseResult: "", // 拒绝原因
      searchParams: {
        merchant_name: "",
        withdraw_deposit_no: "",
      },
      drawerAuditDetail: {
        isShow: false,
        radioStatus: "",
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
    };
  },

  created() {
    this.getList();
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-08-03 14:08:18
     * @Description: 获取列表数据
     */
    async getList(merchant_name, withdraw_deposit_no) {
      this.listLoading = true;
      const { res } = await getWithdrawDepositApply({
        merchant_name,
        withdraw_deposit_no,
        audit_status: 2,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.listLoading = false;
      if (res.error_code === 0) {
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            account_balanceFormat: item.account_balance / 100,
            withdraw_deposit_amountFormat: item.withdraw_deposit_amount / 100,
          };
        });
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (this.list.length > 0) {
          this.$emit("changeMarks", true);
        } else {
          this.$emit("changeMarks", false);
        }
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 15:04:24
     * @Description: 点击查询
     */
    handleSearch() {
      this.pagination.current = 1;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.withdraw_deposit_no || null
      );
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 15:04:31
     * @Description: 重置表单
     */
    handleReset() {
      this.pagination.current = 1;
      this.form.resetFields();
      this.searchParams.merchant_name = "";
      this.searchParams.withdraw_deposit_no = "";
      this.getList();
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 19:28:30
     * @Description: 点击进入详情
     */
    drawerDetail(record) {
      this.record = record;
      this.getDetail();
      this.drawerAuditDetail.isShow = true;
      this.drawerAuditDetail.radioStatus = "";
      this.refuseResult = "";
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 18:07:38
     * @Description: 获取详情数据
     */
    async getDetail() {
      const { res } = await getWithdrawDepositApplyDetails(this.record.id);
      if (res.error_code === 0) {
        this.details = res.data;
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-04 14:39:28
     * @Description: 详情点击提交
     */
    async submit() {
      if (!this.drawerAuditDetail.radioStatus) {
        this.$message.warn("请选择审核结果。并不允许提交。");
        return;
      }
      if (this.drawerAuditDetail.radioStatus == "3" && !this.refuseResult) {
        this.$message.warn("请填写拒绝理由");
        return;
      }

      const { res } = await putWithdrawDepositApply(this.record.id, {
        audit_status: this.drawerAuditDetail.radioStatus,
        failed_reason: this.refuseResult,
        audit_user_id: JSON.parse(localStorage.getItem("vuex")).user.userinfo
          .id,
        audit_user_name: JSON.parse(localStorage.getItem("vuex")).user.userinfo
          .username,
      });
      if (res.error_code == 0) {
        this.drawerAuditDetail.isShow = false;
        this.$message.success("成功");
        this.getList();
      } else {
        this.drawerAuditDetail.isShow = false;
      }
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.withdraw_deposit_no || null
      );
    },
    onDetailClose() {
      this.drawerAuditDetail.isShow = false;
      this.refuseResult = "";
    },
  },
};
</script>

<style lang="less" scoped>
.header_info {
  line-height: 40px;
  margin-bottom: 15px;
}
.title {
  font-weight: bold;
  font-size: 15px;
}
.content {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 15px;
      text-align: center;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .btn-operate {
    margin: 20px 0;
    float: right;
  }
}
</style>


