<!--
 * @Author: 肖槿
 * @Date: 2020-06-18 14:29:23
 * @Description: sku详情
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-12 16:25:17
 * @FilePath: \s2b-admin\src\components\admin\shop\sku-info.vue
-->
<template>
  <div>
    <a-table :pagination="false" :columns="columns" :data-source="tableData" bordered row-key="id">
      <template slot="supply_price" slot-scope="row">{{
        row.supply_price | thousandsFormat
      }}</template>
      <template slot="recommend_price" slot-scope="row">{{
        row.recommend_price | thousandsFormat
      }}</template>
      <template slot="xd_price" slot-scope="row">{{ row.xd_price | thousandsFormat }}</template>
    </a-table>
  </div>
</template>

<script>
import { deepClone } from '@/utils/';
import { SkuColumn } from '@/const/shop';
import { amountCheck, positiveInt } from '@/utils/shop';
import { thousandsFormat } from '@/filters/shop';
export default {
  data() {
    return {
      goodsData: {},
      columns: deepClone(SkuColumn),
      thousandsFormat,
      tableData: [],
      isEdit: false,
      price: '',
      stock: '',
      priceFlag: true,
      stockFlag: true,
      amountCheck,
      positiveInt,
    };
  },

  methods: {
    show(row, isShow) {
      if (row.spu_status === 2 || row.spu_status === 1 || (row.spu_status === 3 && isShow)) {
        this.columns[4].title = '库存(件)';
        this.columns.splice(3, 1);
        this.columns.splice();
        this.columns.pop();
      }
      if (!row.specs) return [];
      this.goodsData = row;
      this.isEdit =
        (row.spu_status === 3 && !isShow) ||
        (row.spu_status === 6 && !isShow) ||
        (row.spu_status === 7 && !isShow);
      if (this.isEdit) {
        this.stockFlag = false;
        this.priceFlag = false;
        this.goodsData.sku_infos.forEach(v => {
          if (v.xd_price) {
            v.xd_price = this.thousandsFormat(v.xd_price);
          }
        });
        this.columns[5].title = '上架库存(件)';
        this.columns[3].title = this.headRender(this.columns[3].title, 'price');
        this.columns[5].title = this.headRender(this.columns[5].title, 'stock');
        this.columns[3].width = 150;
        this.columns[5].width = 150;
        this.columns[3].customRender = (value, row, index) => {
          return this.bodyRender(value, row, index, 'xd_price');
        };
        this.columns[5].customRender = (value, row, index) => {
          return this.bodyRender(value, row, index, 'xd_stock_num_plus');
        };
      }
      const sku_infos = this.goodsData.sku_infos ? deepClone(this.goodsData.sku_infos) : [];
      const leaf1 = this.goodsData.specs.find(v => v.sort === 0 && v.pid === 0); // A级
      const leaf1_a = this.goodsData.specs.filter(v => v.pid === leaf1.id); //

      const leaf2 = this.goodsData.specs.find(v => v.sort === 1 && v.pid === 0); // B级 每个A级下有1个B
      const leaf1_b = leaf2 ? this.goodsData.specs.filter(v => v.pid === leaf2.id) : []; //

      const leaf3 = this.goodsData.specs.find(v => v.sort === 2 && v.pid === 0); // C级 每个B级下有1个C
      const leaf1_c = leaf3 ? this.goodsData.specs.filter(v => v.pid === leaf3.id) : []; //
      if (!leaf1) return [];

      // 如果没有1级，返回空
      // 如果有1级，没有2级

      if (leaf1_a.length) {
        leaf1_a.forEach(v => {
          this.goodsData.spu_spec_images.forEach(n => {
            if (v.id === n.spec_id) {
              v.avatar = n.img_url;
            }
          });
        });
      }
      const _list = [];
      // 将各层规格逐层遍历，并规定表格column数据，
      if (leaf3) {
        this.pushThird(leaf1, leaf1_a, leaf1_b, leaf2, leaf3);
        leaf1_a.forEach(a => {
          leaf1_b.forEach(b => {
            leaf1_c.forEach(c => {
              const _i = `${a.id},${b.id},${c.id}`; // 声明id序号
              const _c = sku_infos.find(d => d.specs_order === _i); // 在所有数据中找到与之匹配的那条数据
              let _column = deepClone(_c);
              if (!_column) _column = {};
              _column.third = c.name; // 各层
              _column.first = a.name;
              _column.second = b.name;
              _column.third_id = c.id;
              _column.first_id = a.id;
              _column.second_id = b.id;
              _column.avatar = a.avatar;
              _column.xd_stock_num_plus = '';
              _column.xd_price = _column.xd_price || '';
              _list.push(_column);
            });
          });
        });
      } else if (leaf2) {
        this.pushSecond(leaf1, leaf1_a, leaf2);
        leaf1_a.forEach(a => {
          leaf1_b.forEach(b => {
            const _i = `${a.id},${b.id}`;
            const _c = sku_infos.find(d => d.specs_order === _i);
            let _column = deepClone(_c);
            if (!_column) _column = {};
            _column.first = a.name;
            _column.second = b.name;
            _column.first_id = a.id;
            _column.second_id = b.id;
            _column.avatar = a.avatar;
            _column.xd_stock_num_plus = '';
            _column.xd_price = _column.xd_price || '';
            _list.push(_column);
          });
        });
      } else if (leaf1) {
        this.pushFirst(leaf1);
        leaf1_a.forEach(a => {
          const _i = `${a.id}`;
          const _c = sku_infos.find(d => d.specs_order === _i);
          let _column = deepClone(_c);
          _column.first = a.name;
          _column.first_id = a.id;
          _column.avatar = a.avatar;
          _column.xd_stock_num_plus = '';
          _column.xd_price = _column.xd_price || '';
          _list.push(_column);
        });
      }
      this.tableData = _list;
    },
    columnRender(cc, value, index, row) {
      let obj = {
        children: '',
        attrs: {},
      };
      if (index % cc === 0) {
        obj = {
          children: (
            <div style="text-align:center">
              {row && row.avatar && (
                <viewer style="margin-bottom:10px">
                  <img style="width:50px;height:50px;" src={row.avatar} />
                </viewer>
              )}
              <span>{value}</span>
            </div>
          ),
          attrs: { rowSpan: cc },
        };
      } else {
        obj.attrs.colSpan = 0;
      }
      return obj;
    },
    pushFirst(leaf1) {
      this.columns.unshift({
        title: leaf1.name,
        dataIndex: 'first',
        width: 120,
      });
    },
    pushSecond(leaf1, leaf1_a, leaf2) {
      this.columns.unshift(
        ...[
          {
            title: leaf1.name,
            dataIndex: 'first',
            width: 120,
            customRender: (value, row, index) => {
              const cc = this.tableData.length / leaf1_a.length;
              return this.columnRender(cc, value, index, row);
            },
          },
          {
            title: leaf2.name,
            dataIndex: 'second',
            width: 120,
          },
        ],
      );
    },
    pushThird(leaf1, leaf1_a, leaf1_b, leaf2, leaf3) {
      this.columns.unshift(
        ...[
          {
            title: leaf1.name,
            dataIndex: 'first',
            width: 120,
            customRender: (value, row, index) => {
              const cc = this.tableData.length / leaf1_a.length;
              return this.columnRender(cc, value, index, row);
            },
          },
          {
            title: leaf2.name,
            dataIndex: 'second',
            width: 120,
            customRender: (value, _, index) => {
              const cc = this.tableData.length / leaf1_a.length / leaf1_b.length;
              return this.columnRender(cc, value, index);
            },
          },
          {
            title: leaf3.name,
            dataIndex: 'third',
            width: 120,
          },
        ],
      );
    },
    hide() {
      this.columns = deepClone(SkuColumn);
      this.price = '';
      this.stock = '';
      this.tableData = [];
    },
    headRender(title, type) {
      return (
        <div style="text-align:center;" class="sku-info-header">
          <span>{title}</span>
          <a-input-number
            v-model={this[type]}
            placeholder="批量设置"
            min="0"
            size="small"
            onBlur={e => {
              this.batchHandler(e, type);
            }}
          />
        </div>
      );
    },
    bodyRender(value, row, index, type) {
      let obj = {
        children: (
          <a-input-number
            v-model={row[type]}
            min="0"
            size="small"
            type="number"
            onBlur={e => {
              this.changeHandler(e, type, row);
            }}
          />
        ),
      };

      return obj;
    },
    changeHandler(e, type, row) {
      const val = e.target.value;
      if (type === 'xd_stock_num_plus') {
        if (Number(val) > row.stock_num) {
          this.$message.error('上架库存应小于未上架库存');
          this.stockFlag = true;
          return;
        } else if (val.length == 0) {
          this.$message.error('上架库存不能为空');
          this.stockFlag = true;
          return;
        } else if (!/^[1-9]\d*$/.test(Number(val))) {
          this.$message.error('上架库存不应该是小数');
          this.stockFlag = true;
          return;
        } else {
          this.stockFlag = false;
        }
      } else {
        if ((!this.amountCheck(val) || val === '0') && val !== '') {
          this.$message.error('请输入不为0的正数，仅允许输入两位小数');
          this.priceFlag = true;
          return;
        }
      }

      this.stockFlag = false;
      this.priceFlag = false;
    },
    batchHandler(e, type) {
      const val = e.target.value;
      if (val === '') return;
      if (type === 'stock') {
        for (let i = 0; i < this.tableData.length; i++) {
          if (Number(val) > Number(this.tableData[i].stock_num)) {
            this.$message.error('上架库存应小于未上架库存');
            this.stockFlag = true;
            return;
          } else if (!/^[1-9]\d*$/.test(Number(val))) {
            this.$message.error('上架库存不应该是小数');
            this.stockFlag = true;
            return;
          } else {
            this.stockFlag = false;
          }
        }
        this.tableData.forEach(n => {
          this.$set(n, 'xd_stock_num_plus', val);
        });
      } else {
        if (!this.amountCheck(val) || val === '0') {
          this.$message.error('请输入不为0的正数，仅允许输入两位小数');
          this.priceFlag = true;
          return;
        }
        this.tableData.forEach(n => {
          this.$set(n, 'xd_price', val);
        });
      }
      this.stockFlag = false;
      this.priceFlag = false;
    },
  },
};
</script>

<style lang="less">
.sku-info-header {
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}
</style>
