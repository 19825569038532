<!--
 * @Author: 肖槿
 * @Date: 2020-06-13 16:24:35
 * @Description: 商品弹框
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-14 10:20:49
 * @FilePath: \s2b-admin\src\components\admin\shop\goods-audit.vue
-->
<template>
  <div class="goods-audit">
    <a-spin :spinning="baseInfoLoading">
      <a-card
        size="small"
        v-if="isShow && (goodsData.spu_status === 2 || goodsData.spu_status === 5)"
        style="margin-bottom: 20px"
      >
        <div slot="title">
          <span>审核结果</span>
          <template>
            <a-divider type="vertical" />
            <span style="color: #f50"
              >{{ goodsData.spu_status === 5 ? '小店审核' : '初审' }}不通过</span
            >
          </template>
        </div>
        <a-row>
          <a-col span="24" class="detail-item">
            <span class="label">拒绝理由：</span>
            <span class="content">{{ goodsData.spu_info_audit.remark || '--' }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">审核人：</span>
            <span class="content">{{ goodsData.spu_info_audit.audit_user_name }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">审核时间：</span>
            <span class="content">{{
              goodsData.spu_info_audit.update_time | timeFormat('YYYY-MM-DD HH:mm')
            }}</span>
          </a-col>
        </a-row>
      </a-card>
      <!-- [商品信息] -->
      <a-card size="small" class="detail-card">
        <div slot="title">商品信息</div>
        <a-row>
          <a-col span="24" class="detail-item">
            <span class="label">所属商家：</span>
            <span class="content">{{ goodsData.merchant_name || '--' }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">所属类目：</span>
            <span class="content"
              >{{ goodsData.first_cat_id | categoryFilter(allCategoryList) }}/{{
                goodsData.second_cat_id | categoryFilter(allCategoryList)
              }}/{{ goodsData.third_cat_id | categoryFilter(allCategoryList) }}</span
            >
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品名称：</span>
            <span class="content">{{ goodsData.spu_name || '--' }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品编码：</span>
            <span class="content">{{ goodsData.out_spu_id || '--' }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">轮播图：</span>
            <viewer :images="carousel" style="display: inline-block">
              <img v-for="v in carousel" :key="v.id" :src="v.img_url" alt class="carousel" />
            </viewer>
          </a-col>
          <a-col span="24" class="detail-item" style="margin-top: 10px">
            <span class="label">商品详情：</span>
            <viewer :images="infoImg" style="display: inline-block">
              <img v-for="v in infoImg" :key="v.id" :src="v.img_url" alt class="carousel" />
            </viewer>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">供货价：</span>
            <span class="content">{{ goodsData.supply_price_range }}元</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品重量：</span>
            <span class="content">{{ goodsData.weight }}克</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品卖点：</span>
            <span class="content">{{
              goodsData.spu_info_desc ? goodsData.spu_info_desc.usp : ''
            }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">推荐语：</span>
            <span class="content">{{
              goodsData.spu_info_desc ? goodsData.spu_info_desc.recommend_remark : ''
            }}</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品属性：</span>
            <span class="content" v-for="v in spu_attr" :key="v" style="padding-right: 20px">{{
              v
            }}</span>
          </a-col>
        </a-row>
      </a-card>
      <!-- [上架设置] -->
      <a-card size="small" style="margin-top: 12px">
        <div slot="title">上架设置</div>
        <a-row>
          <template v-if="showShop">
            <a-col span="24" class="detail-item">
              <span class="label"> <b style="color: #f50; font-weight: 400">*</b>上架小店： </span>
              <a-select
                placeholder="请选择小店"
                :disabled="isShow || goodsData.spu_status === 7"
                size="small"
                v-model="goodsData.shop_id"
                :getPopupContainer="positionContainer"
                style="width: 180px"
              >
                <a-select-option v-for="v in shopList" :key="v.id" :value="v.id">{{
                  v.name
                }}</a-select-option>
              </a-select>
            </a-col>
            <a-col span="24" class="detail-item">
              <span class="label"> <b style="color: #f50; font-weight: 400">*</b>客服电话： </span>
              <a-input
                :disabled="isShow"
                size="small"
                :maxLength="18"
                v-model="goodsData.service_mobile"
                placeholder="请输入客服电话"
                class="ipt"
              />
            </a-col>
            <a-col span="24" class="detail-item">
              <div id="ccav" style="position: relative">
                <span class="label">商品售价(元)：</span>
                <a-select
                  placeholder="请选择商品售价"
                  size="small"
                  allowClear
                  v-model="goodsData.discount_price"
                  :getPopupContainer="positionContainer"
                  :disabled="isShow || goodsData.spu_status === 7"
                  style="width: 180px"
                >
                  <a-select-option v-for="(v, k) in priceList" :key="k" :value="v">{{
                    v
                  }}</a-select-option>
                </a-select>
                <a-alert
                  style="
                    margin-left: 20px;
                    display: inline-block;
                    width: 400px;
                    padding: 0 10px;
                    font-size: 12px;
                  "
                  message="售卖价必须在订单套餐价格范围内;  如不填写将用最低销售价作为售价"
                  type="info"
                />
              </div>
            </a-col>
            <a-col span="24" class="detail-item">
              <span class="label">
                <b style="color: #f50; font-weight: 400">*</b>商品市场价(元)：
              </span>
              <a-input
                size="small"
                v-model="goodsData.market_price"
                placeholder="请输入商品市场价"
                :disabled="isShow || goodsData.spu_status === 7"
                class="ipt"
              />
              <a-alert
                style="
                  margin-left: 20px;
                  display: inline-block;
                  width: 400px;
                  padding: 0 10px;
                  font-size: 12px;
                "
                message="市场价是店铺商品详情的划线价格；需高于商品售价"
                type="info"
              />
              <p v-show="marketFlag" style="margin-left: 120px; color: #f50">
                商品市场价需要大于商品售价
              </p>
            </a-col>
          </template>
          <a-col span="24" class="detail-item">
            <span class="label">SKU详情：</span>
            <sku-info ref="skuInfos" class="sku-info" />
          </a-col>
        </a-row>
      </a-card>
      <!-- [服务与资质] -->
      <a-card size="small" style="margin-top: 12px">
        <div slot="title">服务与资质</div>
        <a-row>
          <a-col span="24" class="detail-item">
            <span class="label">发货天数：</span>
            <span class="content">{{ goodsData.delivery_day || '0' }}天</span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">退货地址：</span>
            <span class="content">
              {{ goodsData.spu_info_desc.province_code | division }} /
              {{ goodsData.spu_info_desc.city_code | division }} /
              {{ goodsData.spu_info_desc.district_code | division }} /
              {{ goodsData.spu_info_desc.address }}
            </span>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">商品资质：</span>
            <viewer :images="qualification" style="display: inline-block">
              <img v-for="v in qualification" :key="v.id" :src="v.img_url" alt class="carousel" />
            </viewer>
          </a-col>
          <a-col span="24" class="detail-item">
            <span class="label">质检报告：</span>
            <viewer :images="quality" style="display: inline-block; margin-top: 10px">
              <img v-for="v in quality" :key="v.id" :src="v.img_url" alt class="carousel" />
            </viewer>
          </a-col>
        </a-row>
      </a-card>
      <a-card size="small" style="margin-top: 12px">
        <div slot="title">备注</div>
        <a-row>
          <a-col>
            <span>{{ myRemark }}</span>
          </a-col>
        </a-row>
      </a-card>
      <a-card size="small" v-if="!isShow" style="margin-top: 12px">
        <div slot="title" v-if="goodsData.spu_status === 1">审核结果</div>
        <a-row>
          <a-col v-if="goodsData.spu_status === 1" span="24" class="detail-item">
            <a-radio-group v-model="radioGroup">
              <a-radio :value="3">通过</a-radio>
              <a-radio :value="2">拒绝</a-radio>
            </a-radio-group>
            <div class="certification-area">
              <a-textarea
                v-model="remark"
                :placeholder="placeText"
                :auto-size="{ minRows: 3 }"
                :maxLength="500"
              />
            </div>
          </a-col>
          <a-col span="24" class="detail-item">
            <div class="certification-btn">
              <a-button style="margin-right: 30px" @click="closeHandler">取消</a-button>
              <a-button type="primary" @click="submit">提交</a-button>
            </div>
          </a-col>
        </a-row>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getExternalSpuInfoById, putSpuInfoAudit, putPutOnShelves } from '@/service/shop';
import skuInfo from './sku-info';
import { mapGetters } from 'vuex';
import { amountCheck, checkLandline, checkPhone } from '@/utils/shop';
import { thousandsFormat } from '@/filters/shop';
import { YuantoFen } from '@/utils/func';

export default {
  components: {
    skuInfo,
  },
  props: {
    currentTab: {
      type: Number,
      default: 1,
    },
    allCategoryList: {
      type: Array,
      default: () => [],
    },
    shopList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseInfoLoading: true,
      thousandsFormat,
      radioGroup: '',
      goodsData: {
        spu_info_desc: {},
        spu_images: [],
        spu_spec_images: [],
        sku_infos: [],
      },
      isShow: false,
      isEdit: false,
      marketFlag: false,
      amountCheck,
      checkLandline,
      checkPhone,
      remark: '',
    };
  },
  computed: {
    priceList() {
      let arr = this.$refs.skuInfos.tableData.map(v => Number(v.xd_price));
      arr = [...new Set(arr.filter(v => Number(v)))];
      return arr;
    },
    spu_attr() {
      if (!this.goodsData.spu_info_desc || !this.goodsData.spu_info_desc.spu_attr) {
        return ['--'];
      } else if (this.goodsData.spu_info_desc.spu_attr.length > 3) {
        const arr = JSON.parse(this.goodsData.spu_info_desc.spu_attr);
        let p = [];
        arr.forEach(v => {
          p.push(`${Object.keys(v).toString()}:${Object.values(v).toString()}`);
        });
        return p;
      } else {
        return ['--'];
      }
    },
    placeText() {
      return this.radioGroup === 2
        ? '拒绝原因'
        : this.radioGroup === 3
        ? '备注信息'
        : '请选择审核结果';
    },
    carousel() {
      return this.goodsData.spu_images.filter(v => v.img_type === 1);
    },
    infoImg() {
      return this.goodsData.spu_images.filter(v => v.img_type === 2);
    },
    qualification() {
      return this.goodsData.spu_images.filter(v => v.img_type === 3);
    },
    quality() {
      return this.goodsData.spu_images.filter(v => v.img_type === 4);
    },
    showShop() {
      return (
        [4, 6, 7, 8].includes(this.goodsData.spu_status) ||
        (this.goodsData.spu_status === 3 && !this.isShow)
      );
    },
    ...mapGetters(['getUserinfo']),
    // 备注
    // 注意: 当前设计中商品的 remark 字段从 spu_info_audit.remark 取，spu_info_audit
    // 在商品审核过之前不存在，该字段仅被用于存储审核通过与否的理由而不是商品本身的备注信息
    myRemark() {
      if (this.goodsData === undefined) {
        return '--';
      } else if (
        this.goodsData.spu_info_audit === null ||
        this.goodsData.spu_info_audit === undefined
      ) {
        return '--';
      } else {
        return this.goodsData.spu_info_audit.remark;
      }
    },
  },
  methods: {
    async show(row, isShow) {
      this.baseInfoLoading = true;
      const { res } = await getExternalSpuInfoById(row.id);
      this.goodsData = res.data;
      this.goodsData.merchant_name = row.merchant_name; // thousandsFormat
      this.goodsData.discount_price =
        this.goodsData.discount_price === 0 ? undefined : this.goodsData.discount_price / 100;
      this.goodsData.market_price =
        this.goodsData.market_price === 0 ? undefined : this.goodsData.market_price / 100;
      this.goodsData.shop_id = this.goodsData.shop_id === 0 ? undefined : this.goodsData.shop_id;

      this.goodsData.service_mobile =
        this.goodsData.service_mobile == 0 ? undefined : this.goodsData.service_mobile;

      this.baseInfoLoading = false;
      this.isShow = isShow;

      this.$nextTick(() => {
        this.$refs.skuInfos.show(this.goodsData, isShow);
      });
    },
    hide() {
      this.$refs.skuInfos.hide();
      this.remark = '';
      this.radioGroup = '';
      this.marketFlag = false;
    },
    closeHandler() {
      this.$emit('close');
    },
    submit() {
      if (this.goodsData.spu_status === 1) {
        this.reviewHandler(); // 初审
      } else if ([3, 6, 7].includes(this.goodsData.spu_status)) {
        this.putOnHandler(); // 上架
      }
    },
    positionContainer() {
      return document.getElementById('ccav');
    },
    async reviewHandler() {
      if (!this.radioGroup) {
        this.$message.error('请选择审核结果');
        return;
      } else if (this.radioGroup === 2 && !this.remark.trim()) {
        this.$message.error('拒绝原因不能为空');
        return;
      }
      const params = {
        spu_status: this.radioGroup,
        audit_user_id: this.getUserinfo.id,
        audit_user_name: this.getUserinfo.username,
        spu_id: this.goodsData.id,
        remark: this.remark,
      };
      this.baseInfoLoading = true;
      const { res } = await putSpuInfoAudit(params);
      this.baseInfoLoading = false;
      if (res.success) {
        this.$emit('submit');
        this.$message.success('提交成功');
      }
    },
    async putOnHandler() {
      const { id, shop_id, service_mobile, discount_price, market_price } = this.goodsData;
      const params = {
        shop_id,
        spu_id: id,
        service_mobile,
        discount_price: YuantoFen(discount_price),
        market_price: YuantoFen(market_price),
        sku_shelves_infos: [],
      };
      if (!shop_id) {
        this.$message.error('请选择需要上架的小店');
        return;
      } else if (!this.amountCheck(market_price) || Number(market_price) === 0) {
        this.$message.error('商品市场价请输入不为0的正数，最多输入两位小数');
        return;
      } else if (!service_mobile) {
        this.$message.error('请输入客服电话');
        return;
      }
      //  else if(!this.checkLandline(service_mobile) && !this.checkPhone(service_mobile)) {
      //   this.$message.error('请输入正确手机号或座机号')
      //   return
      // }
      // this.marketFlag = Number(market_price) < Number(discount_price);
      if (this.$refs.skuInfos.tableData.stockFlag || this.$refs.skuInfos.tableData.priceFlag) {
        // if (this.$refs.skuInfos.stockFlag || this.$refs.skuInfos.priceFlag) {

        this.$message.error('请输入正确的库存和销售价');
        return;
      } else if (service_mobile.length < 7) {
        this.$message.error('请填写正确的客服电话');
        return;
      } else if (Number(market_price) <= Number(discount_price)) {
        this.$message.error('商品市场价需要大于商品售价');
        return;
      }

      let flag = true;
      this.$refs.skuInfos.tableData.forEach(v => {
        const { id, xd_price, xd_stock_num_plus } = v;
        if (flag) {
          flag = !(Number(xd_price) && Number(xd_stock_num_plus));
        }
        params.sku_shelves_infos.push({
          sku_id: id,
          xd_price: YuantoFen(xd_price),
          xd_stock_num_plus: Number(xd_stock_num_plus),
        });
      });

      if (!params.discount_price) {
        const _price = params.sku_shelves_infos.map(v => v.xd_price);
        params.discount_price = Math.min(..._price.filter(v => v !== 0));
      }

      if (
        this.priceList.indexOf(this.goodsData.discount_price) < 0 ||
        this.goodsData.discount_price.length == 0
      ) {
        let tempList = this.$refs.skuInfos.tableData.map(item => {
          return parseFloat(item.xd_price);
        });
        //  算出最小
        params.discount_price = YuantoFen(this.mathMin(tempList));
      }

      params.sku_shelves_infos = JSON.stringify(params.sku_shelves_infos);
      this.baseInfoLoading = true;
      if (flag) {
        this.$message.error('至少需要上架一件商品');
        this.baseInfoLoading = false;
        return;
      }

      const { res } = await putPutOnShelves(params);
      this.baseInfoLoading = false;
      if (res.success) {
        this.$message.success('提交成功');
        this.$emit('submit');
      }
    },
    // 找出数组中最小的数
    mathMin(arrs) {
      let min = arrs[0];
      for (let i = 1, ilen = arrs.length; i < ilen; i += 1) {
        if (arrs[i] < min) {
          min = arrs[i];
        }
      }
      return min;
    },
  },
};
</script>

<style lang="less" scoped>
.goods-audit {
  & .detail-item {
    line-height: 40px;
    & .label {
      display: inline-block;
      width: 120px;
      text-align: right;
      font-weight: 900;
      color: #000;
    }
    & .ipt {
      width: 180px;
    }
    & img.carousel {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      vertical-align: top;
    }
    & .sku-info {
      display: inline-block;
      width: 850px;
      vertical-align: top;
      margin-bottom: 10px;
    }
  }
  & .certification-area {
    margin-top: 10px;
  }
  & .certification-btn {
    text-align: center;
  }
}
</style>
