<!--
 * @Author: 矢车
 * @Date: 2020-07-24 10:24:15
 * @LastEditors: 矢车
 * @LastEditTime: 2020-09-18 17:42:29
 * @Descripttion: 账户管理 - 提现待打款
-->

<template>
  <div>
    <div class="header_info">
      <a-form layout="inline" :form="form">
        <div class="filter-condition-form">
          <a-form-item label="商家名称：">
            <a-input v-model="searchParams.merchant_name" allowClear placeholder="请输入商家名称" style="width:190px;" />
          </a-form-item>
          <a-form-item label="提现单号：">
            <a-input v-model="searchParams.withdraw_deposit_no" allowClear placeholder="请输入提现单号" style="width:190px;" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="handleSearch" html-type="submit" :loading="listLoading" style="margin-right:20px;">查询</a-button>
            <a-button @click="handleReset" :loading="listLoading">重置</a-button>
          </a-form-item>
        </div>
      </a-form>
    </div>

    <a-table v-auth="'account_manage_list'" :rowKey="record => record.id" :columns="WithdrawStayPayColumn" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="apply_time" slot-scope="record">{{record.apply_time | dateFilter}}</template>
      <template slot="account" slot-scope="record">
        <a-button type="primary" size="small" @click="drawerDetail(record, 'check')">查看</a-button>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="drawerDetail(record)" v-auth="'shop_account_manage_complete_payment'">完成打款</a-button>
      </template>
    </a-table>

    <template>
      <a-drawer :destroyOnClose="true" title="商家账户" :width="500" :visible="drawerAccount.isShow" :body-style="{ paddingBottom: '80px' }" @close="onAccountClose">
        <a-row>
          <a-col :span="24"><span class="title">收款账户信息</span></a-col>
          {{record.merchant_bank}}
          <a-col :span="24">开户行：{{record.merchant_bank_info && record.merchant_bank_info.bank_name}}</a-col>
          <a-col :span="24">账号：{{record.merchant_bank_info && record.merchant_bank_info.account_number}}</a-col>
        </a-row>
      </a-drawer>
    </template>

    <template>
      <a-drawer :destroyOnClose="true" title="完成打款" :width="800" :visible="drawerFinish.isShow" :body-style="{ paddingBottom: '80px' }" @close="onFinishClose">
        <div class="finishRow">
          <a-row>
            <a-col :span="24"><label style="color: red;">*</label> 上传打款凭证：</a-col>
            <a-col :span="24">
              <a-upload showUploadList="false" :customRequest="handleFileUpload" accept=".png, .jpg, .jpeg">
                <a-button v-if="fileList.length != 3">
                  <a-icon type="upload" /> 上传图片 </a-button>
              </a-upload>
              <div style="margin-top: 10px;">
                <div class="inlineBlock" v-for="(item,i) in fileList" :key="i">
                  <img id="imgs" ref="imgs" style="max-width: 150px;margin-right: 10px;" :src="item" alt="">
                  <a-icon type="close-circle" :style="deleteIconStyle" @click="deletePic(i)" />
                </div>
              </div>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-col>
            <a-col :span="24">1. 至少上传1张凭证，最多上传3张凭证</a-col>
            <a-col :span="24">2. 仅支持 png，jpg，jpeg 格式；图片大小不超过2M</a-col>
          </a-row>
          <div class="drawerFooter">
            <a-button :style="{ marginRight: '8px' }" @click="onFinishClose">取消</a-button>
            <a-button type="primary" @click="confirmModal">确定</a-button>
          </div>
          <a-modal v-model="modalFinish" title="确定打款？" ok-text="确认" cancel-text="取消" @ok="submitFinal">
            确定打款后暂不支持修改打款凭证，是否确定打款？
          </a-modal>
        </div>
      </a-drawer>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { getOssToken } from "@/service/user";
import { generateUUID } from "@/utils/index";
import { WithdrawStayPayColumn } from "@/const/shop";
import { getWithdrawDepositApply, postRemit } from "@/service/shop";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  data() {
    return {
      listLoading: false,
      form: this.$form.createForm(this),
      WithdrawStayPayColumn,
      list: [],
      oss: null,
      deleteIconStyle: {
        color: "red",
        position: "absolute",
        marginLeft: "-7px",
        marginTop: "-8px",
        cursor: "pointer",
      },
      record: {}, // 当前点击的表格数据
      searchParams: {
        merchant_name: "",
        withdraw_deposit_no: "",
      },
      drawerAccount: {
        isShow: false,
      },
      drawerFinish: {
        isShow: false,
      },
      modalFinish: false,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
      // --- 上传图片有关
      previewVisible: false,
      previewImage: "",
      fileList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * @Author: 矢车
     * @Date: 2020-08-03 14:10:18
     * @Description: 获取列表数据
     */
    async getList(merchant_name, withdraw_deposit_no) {
      this.listLoading = true;
      const { res } = await getWithdrawDepositApply({
        merchant_name,
        withdraw_deposit_no,
        audit_status: 4,
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      });
      this.listLoading = false;
      if (res.error_code === 0) {
        this.list = res.data.rows.map((item) => {
          return {
            ...item,
            account_balanceFormat: item.account_balance / 100,
            withdraw_deposit_amountFormat: item.withdraw_deposit_amount / 100,
          };
        });
        if (this.list.length > 0) {
          this.$emit("changeMarks", true);
        } else {
          this.$emit("changeMarks", false);
        }
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 15:04:24
     * @Description: 点击查询
     */
    handleSearch() {
      this.pagination.current = 1;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.withdraw_deposit_no || null
      );
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 19:28:30
     * @Description: 点击进入详情
     */
    drawerDetail(record, type) {
      this.record = record;
      if (type == "check") {
        this.drawerAccount.isShow = true;
      } else {
        this.drawerFinish.isShow = true;
        this.oss = null;
        this.fileList = [];
      }
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-03 15:04:31
     * @Description: 重置表单
     */
    handleReset() {
      this.pagination.current = 1;
      this.form.resetFields();
      this.searchParams.merchant_name = "";
      this.searchParams.withdraw_deposit_no = "";
      this.getList();
    },

    // 跳页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getList(
        this.searchParams.merchant_name || null,
        this.searchParams.withdraw_deposit_no || null
      );
    },
    onAccountClose() {
      this.drawerAccount.isShow = false;
    },
    onFinishClose() {
      this.drawerFinish.isShow = false;
    },

    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) {
        this.oss = res.data;
      }
    },
    // 文件上传方法
    async handleFileUpload(file) {
      if (file.file.size / 1024 / 1024 > 2) {
        this.$message.error("上传的文件过大！");
        return;
      }
      if (
        !this.oss ||
        this.oss.expire < new Date().getTime().toString().substring(0, 10)
      ) {
        await this.getOssToken();
      }

      if (!this.oss) {
        this.$message.warn("上传失败");
        return false;
      }
      const formData = new FormData();
      const pathArr = file.file.name.split(".");
      const key = `${this.oss.dir}douyin-goods/${generateUUID()}.${
        pathArr[pathArr.length - 1]
      }`;
      formData.append("key", key);
      formData.append("policy", this.oss.policy);
      formData.append("signature", this.oss.signature);
      formData.append("success_action_status", "200");
      formData.append("OSSAccessKeyId", this.oss.accessid);
      formData.append("file", file.file);

      axios({
        method: "post",
        url: this.oss.host,
        data: formData,
      })
        .then(async (response) => {
          const { err } = response;
          if (!err) {
            //  获取到从 oss 返回的 图片 链接
            this.fileList.push(this.oss.host + "/" + key);
          } else {
            this.$message.error("上传失败，请稍后重试");
          }
        })
        .catch((error) => {
          this.$message.error(`${error}`);
        });
    },

    /**
     * @Author: 矢车
     * @Date: 2020-08-04 17:30:30
     * @Description: 删除图片
     */
    deletePic(index) {
      this.fileList.splice(index, 1);
    },

    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },

    confirmModal() {
      if (this.fileList.length == 0) {
        this.$message.error("请上传打款凭证。并不允许提交");
        return;
      }
      this.modalFinish = true;
    },

    async submitFinal() {
      let tempList = this.fileList.map((item) => {
        return {
          img_url: item,
        };
      });
      const { res } = await postRemit(this.record.id, {
        voucher_img_list: JSON.stringify(tempList),
        admin_user_id: JSON.parse(localStorage.getItem("vuex")).user.userinfo
          .id,
        admin_user_name: JSON.parse(localStorage.getItem("vuex")).user.userinfo
          .username,
      });
      if (res.error_code === 0) {
        this.list = res.data.rows;
        this.drawerFinish.isShow = false;
        this.modalFinish = false;
        this.getList();
        this.fileList = [];
      } else {
        this.drawerFinish.isShow = false;
        this.modalFinish = false;
        this.fileList = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: 600;
  font-size: 16px;
}
.header_info {
  line-height: 40px;
  margin-bottom: 15px;
}
.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
.inlineBlock {
  display: inline-block;
  margin-right: 15px;
  border: 1px solid #e1e2e6;
}
</style>

<style scoped>
>>> .ant-row > div {
  line-height: 40px;
}
>>> .ant-upload-list-text {
  display: none;
}
</style>

